/**
 * Use the CSS tab above to style your Element's container.
 */
import React from 'react';
import {IbanElement} from '@stripe/react-stripe-js';
import {t} from "../../../../I18n/i18n";

// Custom styling can be passed as options when creating an Element.
const IBAN_STYLE = {
    base: {
        color: '#32325d',
        fontSize: '20px',
        '::placeholder': {
            color: '#aab7c4'
        },
        ':-webkit-autofill': {
            color: '#32325d',
        },
        ':focus': {
            iconColor: '#003dff',
        }
    },
    invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
        ':-webkit-autofill': {
            color: '#fa755a',
        },
    },
};

const IBAN_ELEMENT_OPTIONS = {
    supportedCountries: ['SEPA'],
    // Elements can use a placeholder as an example IBAN that reflects
    // the IBAN format of your customer's country. If you know your
    // customer's country, we recommend that you pass it to the Element as the
    // placeholderCountry.
    placeholderCountry: 'FR',
    style: IBAN_STYLE,
};

export default function IbanForm({onSubmit, disabled, onChange, user}) {
    const focus = () => {
        // focus the iban element
        const ibanElement = document.querySelector('input[name="iban"]');
        ibanElement?.focus();
    }

    return (
        <div className="container">
            <form onSubmit={onSubmit}>
                <div style={{display: "flex", flexDirection: "column", gap: 20}}>
                    <div className="form-row inline" style={{display: "flex", flexDirection: "column", gap: 20}}>
                        <div className="col">
                            <div className="input-field outlined">
                                <input
                                    name="accountholder-name"
                                    defaultValue={user.fullname}
                                    required
                                />
                                <label htmlFor="accountholder-name">{t("NAME")}</label>
                            </div>
                        </div>

                        <div className="col">
                            <div className="input-field outlined">
                                <input
                                    name="email"
                                    type="email"
                                    defaultValue={user.email}
                                    required
                                />
                                <label htmlFor="email">{t("EMAIL")}</label>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <label>
                            <h6 style={{paddingInline: 20}}>IBAN</h6>
                            <div style={{borderRadius: 10, backgroundColor: "#eeeeee", padding: 20, cursor: "text"}} onClick={focus}>
                                <IbanElement options={IBAN_ELEMENT_OPTIONS} onChange={onChange}/>
                            </div>
                        </label>
                    </div>
                    <div>
                        <button type="submit" className={`btn blue darken-2 z-depth-0 ${disabled ? "disabled" : ""}`}>
                            {t("SUBMIT")}
                        </button>
                    </div>
                    {/* Display mandate acceptance text. */}
                    <div className="mandate-acceptance" style={{fontStyle: "italic"}}>
                        En fournissant votre IBAN, vous autorisez Temeoo et
                        Stripe, notre prestataire de services de paiement,
                        à envoyer des instructions à votre banque pour débiter votre compte conformément à ces
                        instructions.
                        Les paiements ultérieurs peuvent faire l'objet d'un remboursement de la part de votre banque
                        selon
                        les termes et conditions de votre accord avec votre banque.
                        Le remboursement doit être demandé dans un délai de huit semaines à compter de la date à
                        laquelle votre compte a
                        été débité.
                    </div>
                </div>


            </form>
        </div>
    );
};
