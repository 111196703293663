import React, {useEffect} from 'react';
import InvoiceHeader from "./InvoiceHeader";
import InvoiceBody from "./InvoiceBody";
import InvoiceStamp from "./InvoiceStamp";
import InvoiceDetails from "./InvoiceDetails";
import Helper from "../../../Shared/Helper";

const Document = ({invoice, orthoptist, details, transactions}) => {
    useEffect(() => {
        window.status = "ready"
    }, []);

    const name = () => {
        if (orthoptist.invoice_label) return orthoptist.invoice_label
        return Helper.formatName(orthoptist.prenom, orthoptist.nom)
    }

    const renderDetails = () => {
        if (!details) return null;
        return  <InvoiceDetails details={details.filter((_, index) => index < 200)}/>
    }

    return <div style={{padding: 20, display: "flex", justifyContent: "center"}}>
    <div className="card" style={{minHeight: "297mm", width: "210mm"}}>
        <div className="pdf-document" style={{display: "flex", flexDirection: "column"}}>
            <InvoiceHeader/>
            <div style={{display: "flex", margin: "40px 0"}}>
                <h5 style={{margin: 0}}><b>Facturé à :</b> {name()}</h5>
            </div>
            <div style={{margin: "100px 0 0 0"}}>
                <InvoiceBody invoice={invoice} transactions={transactions}/>
            </div>
            <div id="fixed-div" style={{position: "fixed", top: "180px", left: "800px", opacity: 0.3}}>
                <InvoiceStamp status={invoice.status}/>
            </div>
            {renderDetails()}
        </div>
    </div>
    </div>
}

export default Document;