import React, {useEffect} from 'react';
import DocumentHeader from "./DocumentsComponents/DocumentHeader";
import DocumentMesure from "./DocumentsComponents/DocumentMesure";
import DocumentIntro from "./DocumentsComponents/DocumentIntro";
import DocumentSurvey from "./DocumentsComponents/DocumentSurvey";
import DocumentReview from "./DocumentsComponents/DocumentReview";
import DocumentImg from "./DocumentsComponents/DocumentImg";
import DocumentPrescription from "./DocumentsComponents/DocumentPrescription";
import DocumentConclusion from "./DocumentsComponents/DocumentConclusion";
import DocumentSignature from "./DocumentsComponents/DocumentSignature";

const Amy67Report = ({prescription, patient, appointment, orthoptist, mesures, ophtalmologist, conclusion, message, islocked, user_type}) => {

    useEffect(() => {
        window.status = "ready";
    }, []);

    const renderDiabetes = () => {
        return <>
            <div style={{borderBottom: '0.8mm solid black', width: '100%'}}/>
            <div style={{display: "flex", flexDirection: "row", paddingRight: 40, marginBottom: 5, marginTop: 5}}>
                <h5 style={{marginBlock: 2, width: 140}}>Diabète</h5>
                <h5 style={{marginBlock: 2, width: 220}}>{appointment.amy6_diabete}</h5>
                <h5 style={{marginBlock: 2, width: 140}}>Suivi ophtalmo</h5>
                <h5 style={{marginBlock: 2}}>{appointment.suivi_ophtalmique}</h5>
            </div>
            <div style={{display: "flex", flexDirection: "row", paddingRight: 40, marginBottom: 5}}>
                <h5 style={{marginBlock: 2, width: 140}}>Année de découverte</h5>
                <h5 style={{marginBlock: 2, width: 220}}>{appointment.annee_decouverte_diabete}</h5>
                <h5 style={{marginBlock: 2, width: 140}}>Nom de l'ophtalmo</h5>
                <h5 style={{marginBlock: 2}}>Dr {appointment.ophtalmo_nom}</h5>
            </div>
            <div style={{display: "flex", flexDirection: "row", paddingRight: 40, marginBottom: 5}}>
                <h5 style={{marginBlock: 2, width: 140}}>Dernière Hb1ac</h5>
                <h5 style={{marginBlock: 2, width: 220}}>{appointment.hb1ac}</h5>
                <h5 style={{marginBlock: 2, width: 140}}>Date du dernier exam</h5>
                <h5 style={{marginBlock: 2}}>{appointment.ophtalmologiste_dernier_examen}</h5>
            </div>
            <div style={{display: "flex", flexDirection: "row", paddingRight: 40, marginBottom: 5}}>
                <h5 style={{marginBlock: 2, width: 140}}>HTA</h5>
                <h5 style={{marginBlock: 2, width: 220}}>{appointment.hta}</h5>
            </div>
            <div style={{borderBottom: '0.8mm solid black', width: '100%'}}/>
        </>
    }
    return <div style={{display: "flex", justifyContent: "center"}}>
        <div className="card page" style={{width: "210mm", height: "296mm", margin: 20}}>
            <div className="pdf-document" style={{display: "flex", flexDirection: "column"}}>
                <DocumentHeader orthoptist={orthoptist} ophtalmologist={ophtalmologist} patient={patient}
                                appointment={appointment}/>
                <DocumentIntro orthoptist={orthoptist} appointment={appointment} patient={patient}/>
                <DocumentSurvey appointment={appointment} patient={patient}/>
                {renderDiabetes()}
                <DocumentReview appointment={appointment} patient={patient}/>
                <DocumentMesure mesures={mesures}/>
                <div className="prevent-break">
                    <DocumentImg appointment={appointment}/>
                    <DocumentPrescription prescription={prescription} islocked={islocked} user_type={user_type}/>
                    <DocumentConclusion conclusion={conclusion} appointment={appointment} message={message}/>
                    <DocumentSignature prescription={prescription} ophtalmologist={ophtalmologist}/>
                </div>
            </div>
        </div>
    </div>
}

export default Amy67Report;