import React, {Component} from "react";
import EditButton from "../../../Shared/EditButton";
import axios from "axios";
import {toast} from "react-toastify";
import BorderedDisplay from "../../../Shared/UiKit/BorderedDisplay";
import {PractitionerTypes} from "../../../Shared/Enums/Enums";
import {wrapper} from "../../../I18n/i18n";

const {t} = wrapper("CONSOLE.ORTHOPTIST.BILLING.OPTIONS")
export default class OrthoptistOptions extends Component {
    constructor(props) {
        super(props);
        this.toggleRapport = this.toggleRapport.bind(this)
        this.savePriority = this.savePriority.bind(this)
        this.state = {
            fix: 0,
            variable: 0,
            priority: 0,
            rapport: false,
            cv_enabled: false,
            charge_missing_exams: false,
            orthoptic_act_enabled: false,
            telemedecine_enabled: false,
            loading: true,
            practitioner_type: undefined
        }
    }

    componentDidMount() {
        axios.get(`/admin/parameters/orthoptiste/options/${this.props.orthoptist_id}`).then((data) => {
            this.setState({
                    rapport: data.data.rapport,
                    cv_enabled: data.data.cv_enabled,
                    charge_missing_exams: data.data.charge_missing_exams,
                    orthoptic_act_enabled: data.data.orthoptic_act_enabled,
                    telemedecine_enabled: data.data.telemedecine_enabled,
                    variable: data.data.variable,
                    fix: data.data.amount,
                    practitioner_type: PractitionerTypes[data.data.practitioner_type].code
                }, () => {
                    document.getElementById("rapport").checked = this.state.rapport
                    document.getElementById("cv_enabled").checked = this.state.cv_enabled
                    document.getElementById("charge_missing_exams").checked = this.state.charge_missing_exams
                    document.getElementById("orthoptic_act_enabled").checked = this.state.orthoptic_act_enabled
                    document.getElementById("telemedecine_enabled").checked = this.state.telemedecine_enabled
                    this.setState({loading: false})
                }
            )

        }).catch((err) => {
            toast.error("Impossible de charger les options")
        })
        this.getPriority()
    }

    toggleRapport(e) {
        axios.patch(`/admin/parameters/orthoptiste/${this.props.orthoptist_id}/options/toggleRapport`).then((data) => {
        }).catch((err) => {
            toast.error("Impossible de changer cette option")
        })
    }

    toggleCVEnabled = (e) => {
        axios.patch(`/admin/parameters/orthoptiste/${this.props.orthoptist_id}/options/toggleCvEnabled`).then((data) => {
        }).catch((err) => {
            toast.error("Impossible de changer cette option")
        })
    }

    toggleOrthopticActs = (e) => {
        axios.patch(`/admin/parameters/orthoptiste/${this.props.orthoptist_id}/options/toggleOrthopticActs`).then((data) => {
        }).catch((err) => {
            toast.error("Impossible de changer cette option")
        })
    }

    toggleTelemedecine = (e) => {
        axios.patch(`/admin/parameters/orthoptiste/${this.props.orthoptist_id}/options/toggleTelemedecine`).then((data) => {
        }).catch((err) => {
            toast.error("Impossible de changer cette option")
        })
    }

    toggleChargeMissingExams = (e) => {
        axios.patch(`/admin/parameters/orthoptiste/${this.props.orthoptist_id}/options/toggleChargeMissingExams`).then((data) => {
        }).catch((err) => {
            toast.error("Impossible de changer cette option")
        })
    }

    saveMontant(v) {
        const amount = v ? parseFloat(v.replace(',', '.')).toFixed(2) : 0
        axios.patch(`/admin/parameters/orthoptiste/${this.props.orthoptist_id}/options/fix`, {fix: amount})
            .then((data) => {
                this.setState({
                    fix: amount
                })
            }).catch((err) => {
            toast.error("Impossible de charger les tarifs")
        })
    }

    saveVariable(v) {
        const amount = v ? parseFloat(v.replace(',', '.')).toFixed(2) : 0
        axios.patch(`/admin/parameters/orthoptiste/${this.props.orthoptist_id}/options/variable`, {variable: amount})
            .then((data) => {
                this.setState({
                    variable: amount
                })
            }).catch((err) => {
            toast.error("Impossible de charger les tarifs")
        })
    }

    getPriority() {
        axios.get(`/admin/paiements/orthoptiste/${this.props.orthoptist_id}/priority`).then(resp => {
            this.setState({
                priority: resp.data.priority_price
            })
        })
    }

    savePriority(v) {
        const amount = v ? parseFloat(v.replace(',', '.')).toFixed(2) : 0
        axios.patch(`/admin/paiements/orthoptiste/${this.props.orthoptist_id}/priority`, {
            priority_price: parseFloat(amount)
        }).then(resp => {
            this.setState({
                priority: parseFloat(amount)
            })
        })
    }

    savePractitionerType = (v) => {
        axios.patch(`/admin/parameters/orthoptiste/${this.props.orthoptist_id}/options/practitionerType`, {practitioner_type: v})
            .then((data) => {
                this.setState({
                    practitioner_type: v
                })
            }).catch((err) => {
            toast.error("Impossible de régler le type de praticien")
        })
    }

    render() {
        return <div style={{display: "flex", flexDirection: "column", gap: 20, margin: 10}}>
            <BorderedDisplay text={t("TITLE")}>
                <ul className="collection" style={{borderColor: "transparent"}}>
                    {/* SELECT PRACTITIONER TYPE*/}
                    <li className="collection-item valign-wrapper" style={{borderColor: "transparent"}}>
                        <span className="title">{t("PRACTITIONER_STATUS")}</span>
                        <div style={{marginLeft: "auto"}}>
                            <select className="browser-default"
                                    onChange={e => this.savePractitionerType(e.target.value)}
                                    value={this.state.practitioner_type}>
                                {
                                    Object.values(PractitionerTypes).map((type) => {
                                        return <option key={type.code} value={type.code}>{type.name}</option>
                                    })
                                }
                            </select>
                        </div>
                    </li>
                    {/* SWITCH ORTHOPTIC ACTS*/}
                    <li className="collection-item valign-wrapper" style={{borderColor: "transparent"}}>
                        <span className="title">{t("ORTHOPTIC_ACTS")}</span>
                        <div className="switch right-align" style={{marginLeft: "auto"}}>
                            <label>
                                <input type="checkbox" id="orthoptic_act_enabled"
                                       onChange={this.toggleOrthopticActs}/>
                                <span className="lever"></span>
                            </label>
                        </div>
                    </li>
                    {/* SWITCH TELEMEDICAL ACTS*/}
                    <li className="collection-item valign-wrapper" style={{borderColor: "transparent"}}>
                        <span className="title">{t("TELEMEDECINE_ACTS")}</span>
                        <div className="switch right-align" style={{marginLeft: "auto"}}>
                            <label>
                                <input type="checkbox" id="telemedecine_enabled"
                                       onChange={this.toggleTelemedecine}/>
                                <span className="lever"></span>
                            </label>
                        </div>
                    </li>
                    {/* SWITCH CV ENABLED*/}
                    <li className="collection-item valign-wrapper" style={{borderColor: "transparent"}}>
                        <span className="title">{t("VITAL_CARD_ENABLED")}</span>
                        <div className="switch right-align" style={{marginLeft: "auto"}}>
                            <label>
                                <input type="checkbox" id="cv_enabled" onChange={this.toggleCVEnabled}/>
                                <span className="lever"></span>
                            </label>
                        </div>
                    </li>
                    {/* SWITCH "CHARGE MISSING EXAMINATIONS"*/}
                    <li className="collection-item valign-wrapper" style={{borderColor: "transparent"}}>
                        <span className="title">{t("CHARGE_MISSING_EXAMS")}</span>
                        <div className="switch right-align" style={{marginLeft: "auto"}}>
                            <label>
                                <input type="checkbox" id="charge_missing_exams"
                                       onChange={this.toggleChargeMissingExams}/>
                                <span className="lever"></span>
                            </label>
                        </div>
                    </li>
                    {/* SWITCH "RAPPORT DETAILLE"*/}
                    <li className="collection-item valign-wrapper" style={{borderColor: "transparent"}}>
                        <span className="title">{t("GENERATE_REPORT")}</span>
                        <div className="switch right-align" style={{marginLeft: "auto"}}>
                            <label>
                                <input type="checkbox" id="rapport" onChange={this.toggleRapport}/>
                                <span className="lever"></span>
                            </label>
                        </div>
                    </li>
                    {/* BOUTON "MONTANT"*/}
                    <li className="collection-item valign-wrapper" style={{borderColor: "transparent"}}>
                        <span className="title">{t("FIXED_AMOUNT")}</span>
                        <div className="input-field right-align"
                             style={{marginLeft: "auto", marginTop: "0px", marginBottom: "0px", padding: "0px"}}>
                            <EditButton text={this.state.fix} suffix="€"
                                        onValidation={(v) => this.saveMontant(v)}
                                        filter={(e) => {
                                            return e.target.value.match(/^-?\d*[.,]?\d{0,2}$/g)
                                        }}
                            />
                        </div>
                    </li>
                    {/* BOUTON "VARIABLE"*/}
                    <li className="collection-item valign-wrapper" style={{borderColor: "transparent"}}>
                        <span className="title">{t("VARIABLE_AMOUNT")}</span>
                        <div className="input-field right-align"
                             style={{marginLeft: "auto", marginTop: "0px", marginBottom: "0px", padding: "0px"}}>
                            <EditButton text={this.state.variable} suffix="€"
                                        onValidation={(v) => this.saveVariable(v)}
                                        filter={(e) => {
                                            return e.target.value.match(/^-?\d*[.,]?\d{0,2}$/g)
                                        }}
                            />
                        </div>
                    </li>
                    {/* BOUTON "PRIO"*/}
                    <li className="collection-item valign-wrapper" style={{borderColor: "transparent"}}>
                        <span className="title">{t("PRIORITARY_PRICE")}</span>
                        <div className="input-field right-align"
                             style={{marginLeft: "auto", marginTop: "0px", marginBottom: "0px", padding: "0px"}}>
                            <EditButton text={this.state.priority} suffix="€"
                                        onValidation={(v) => this.savePriority(v)}
                                        filter={(e) => {
                                            return e.target.value.match(/^\d*[.,]?\d{0,2}$/g)
                                        }}
                            />
                        </div>
                    </li>
                </ul>
            </BorderedDisplay>
        </div>
    }
}