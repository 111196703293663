import React from "react";
import axios from "axios";
import {toast} from "react-toastify";
import Validator from "../../Shared/Validator";
import Cookies from "js-cookie";
import PropTypes from "prop-types"
import {t} from "../../I18n/i18n"

function CancelButton(props) {
    const {id, onCancel, negativeColors, small, wide, name, style, title} = props;
    const cancelAppointment = (id) => {
        axios.patch(`/api/appointment/${id}/cancel`, {appointment_id: MesureStore.appointement_id}).then((response) => {
            if (response.data.canceled) {
                toast.success(t("BYPASS_CONSTRAINT.TOAST_CONSULT_CANCEL"));
                onCancel();
            }
        })
    }

    const renderButton = () => {
        if (wide) return <a
            className={`${small ? "btn-small" : "btn"} red darken-2 z-depth-0 truncate`}
            style={{...style, width: "100%"}} data-cy="CancelAppointmentButton">
            {t("BYPASS_CONSTRAINT.CANCEL_CONSULT")}
        </a>
        return <a style={{...style}} title={title}
                  className={`${small ? "btn-small" : "btn"} ${negativeColors ? "white red-text text-darken-2" : "red darken-2"} z-depth-0 truncate`} data-cy="CancelAppointmentButton">
            <i className="material-icons left">cancel</i>
            {t("BYPASS_CONSTRAINT.CANCEL_CONSULT")}
        </a>
    }

    return (
        <Validator
            id={`${name}_${props.id}`}
            onValidation={() => cancelAppointment(id)}
            text={t("BYPASS_CONSTRAINT.SURE_CANCEL_CONSULT")}
            detail={t("BYPASS_CONSTRAINT.INCOMPLETE_CONSULT")}
            validationColor="red darken-2" validationText={t("BYPASS_CONSTRAINT.LEAVE_CONSULT")}
            validationIcon="cancel" abortColor="blue darken-2" abortIcon="chevron_right"
            abortText={t("BYPASS_CONSTRAINT.CONTINUING_CONSULT")}>
            {renderButton()}
        </Validator>
    );
}

CancelButton.propTypes = {
    name: PropTypes.string.isRequired,
    onCancel: PropTypes.func,
    negativeColors: PropTypes.bool,
    small: PropTypes.bool,
    wide: PropTypes.bool
}

CancelButton.defaultProps = {
    onCancel: () => {
        Cookies.remove('last_appointements_data_done')
        Cookies.remove('last_appointement_id')
        Cookies.remove('last_appointement_done')
        Cookies.remove('appointement_id')
        window.location.reload()
    },
    negativeColors: false,
    small: true,
    wide: false,
    name: "cancel_appointment",
    style: {},
    title: ""
}

export default CancelButton;