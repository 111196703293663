import React from "react"
import {observer} from "mobx-react"
import ReactToPrint from 'react-to-print';

import MesureStore from '../MesureStore'
import TypePrescription from "./TypePrescription"
import PrescriptionStore from "../../PrescriptionStore"
import PrintPrescription from "./PrintPrescription"
import PrescriptionImage from "./PrescriptionImage"
import PrintAcces from "./PrintAcces"
import ActiveTabs from "../mesure_components/ActiveTabs"
import ScopedElement from "../../../Shared/ScopedElement";
import {Role} from "../../../Shared/Enums/Scope";
import InputText from "../../../Shared/UiKit/InputText";
import {TypesMesures} from "../../../Shared/Enums/Enums";
import RawSCORButton from "../mesure_components/SideBar/Teletransmission/RawSCORButton";
import {t} from "../../../I18n/i18n"
import SmartTextArea from "../../../Shared/UiKit/SmartTextArea";

@observer
class Prescriptions extends React.Component {
    constructor(props) {
        super(props);
        this.handleWriting = this.handleWriting.bind(this)
        this.cleanData = this.cleanData.bind(this)
        this.choixData = this.choixData.bind(this)
        this.save = this.save.bind(this)
        this.state = {
            txt: "",
            savedDone: false,
            existingMesure: []
        };
    }

    choixData(mesure) {
        MesureStore.typedemesure = mesure.id
        PrescriptionStore.sourceData = mesure.id
        PrescriptionStore.needToSave = true
    }

    cleanData(e) {
        e.preventDefault()
        PrescriptionStore.clearLevel()
    }

    handleWriting(e) {
        this.setState({txt: e.target.value, needToSave: true})

        PrescriptionStore.setTxt(e.target.value)
        PrescriptionStore.needToSave = true
    }

    save(e) {
        e.preventDefault()
        this.setState({needToSave: false, savedDone: false})
        PrescriptionStore.needToSave = false
        PrescriptionStore.saveAll(MesureStore.appointement_id, () => this.setState({savedDone: true}))
    }

    componentDidMount() {
        PrescriptionStore.loadIfExist(MesureStore.appointement_id)
        this.autoSelectMesure()
    }

    autoSelectMesure = () => {
        const alreadySelected = MesureStore.existingMesures.find(m => m.id === MesureStore.typedemesure)
        if (alreadySelected && ![TypesMesures.TENSION, TypesMesures.PACHYMETRY].includes(alreadySelected.titre)) return
        if (MesureStore.existingMesures.length === 0) return

        let mesure = MesureStore.existingMesures.find(m => m.titre === TypesMesures.FINALE_REF)
        if (!mesure) mesure = MesureStore.existingMesures.find(m => m.titre === TypesMesures.REF_SUBJ)
        if (!mesure) {
            MesureStore.updateForm(MesureStore.existingMesures[0]?.id)
            PrescriptionStore.sourceData = MesureStore.existingMesures[0]?.id
            return
        }
        MesureStore.updateForm(mesure.id)
        PrescriptionStore.sourceData = mesure.id
    }

    render() {
        return <div style={{padding: 20, overflowY: "scroll"}}>
            <div className="row">
                <div className="col s3">
                    <ActiveTabs clickFunc={this.choixData}/>
                </div>
                <div className="col s3">
                    <TypePrescription/>
                </div>
                <div className="col s4">
                    <div className="valign-wrapper" style={{gap: 20, justifyContent: "center"}}>
                        <h4 className="center" style={{margin: 0}}>{t("PRESCRIPTION")}</h4>
                        <select value={PrescriptionStore.level}
                                data-cy="PrescriptionLevelSelector"
                                onChange={(e) => {
                                    PrescriptionStore.changeLevel(e.target.value)
                                }}
                                className="browser-default"
                                defaultValue="0"
                                style={{maxWidth: "50%", width: "fit-content"}}>
                            <option value="0" data-cy="PrescriptionLevelOption">{t("PRESCRIPTIONS_PAGES.MAIN")}</option>
                            <option value="1" data-cy="PrescriptionLevelOption">{t("SECONDARY")}</option>
                            <option value="2" data-cy="PrescriptionLevelOption">{t("TERTIARY")}</option>
                        </select>
                        <a className="btn-flat btn-large white red-text"
                           onClick={this.cleanData}><i
                            className="material-icons">delete</i></a>
                    </div>

                    <div style={{margin: 20}}>
                        <SmartTextArea
                            value={PrescriptionStore.prescriptionText ?? ""}
                            onChange={this.handleWriting}
                            style={{height: "40vh", resize: "vertical"}}
                            shortcuts={MesureStore.textual_shortcuts}
                        />
                    </div>
                </div>
                <div className="col s2">
                    <h5 className="center">Actions</h5>
                    <div style={{display: "flex", flexDirection: "column", gap: 20}}>
                        <a onClick={this.save}
                           data-cy="SavePrescriptionButton"
                           className="blue darken-2 z-depth-0 btn-large">
                            <i className="material-icons left">save</i>{t("SAVE")}
                        </a>
                        {/* <p className="center"><a onClick={() => PrescriptionStore.clearTxt()}
                                                     className="waves-effect red waves-light btn-large">Vider tout</a></p>*/}
                            {PrescriptionStore.needToSave === true ? (
                                <p></p>
                            ) : (
                                <div style={{display: "flex", flexDirection: "column", gap: 20}}
                                     data-cy="AccessKeyButton">
                                    <ReactToPrint
                                        trigger={() => <a className="green darken-2 z-depth-0 btn-large">
                                            <i className="material-icons left">print</i>{t("PRESCRIPTIONS_PAGES.ACCESS_KEY")}</a>}
                                        content={() => this.componentRefAcces}
                                    />
                                    {PrescriptionStore.hasPrimaryPrescription ? <ScopedElement exclude={[Role.OPTICIAN]}>
                                            <h3 className="center" style={{margin: 0}}>{t("PRESCRIPTIONS_PAGES.EDIT_SCOR")}</h3>
                                            <div className="row" style={{gap: 10, justifyItems: "center"}}>
                                                <div className="col s12 xl6">
                                                    <a target="_blank" title="Editer SCOR en pdf"
                                                       data-cy="DownloadPrescriptionPDF"
                                                       href="/orthoptiste/download/prescription"
                                                       className="btn green darken-2 z-depth-0 truncate"><i
                                                        className="material-icons left">picture_as_pdf</i>PDF</a>
                                                </div>
                                                <div className="col s12 xl6">
                                                    <PrescriptionImage/>
                                                </div>
                                                <div className="col s12">
                                                    <RawSCORButton scorUrl={MesureStore.selectedChain?.parent_prescription_url}/>
                                                </div>
                                            </div>
                                        </ScopedElement>
                                        :
                                        null}
                                </div>
                            )}
                        </div>


                </div>

            </div>
            <div className="hide">
                <PrintPrescription ref={el => (this.componentRef = el)}/>
                <PrintAcces ref={el => (this.componentRefAcces = el)}/>
            </div>
        </div>
    }
}

export default Prescriptions

{/*                  <ReactToPrint
                  trigger={() => <p className="center"><a className="waves-effect grey waves-light btn">Tél spéciment</a></p>}
                  content={() => this.componentRef}
                  />*/
}
