import React, {Component} from "react";

import {c} from "../I18n/i18n";

export default class WhatsNew extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        var modals = document.getElementById("whatsnew_modal");
        M.Modal.init(modals, {});
    }

    componentWillUnmount() {
        M.Modal.getInstance(document.getElementById("whatsnew_modal")).destroy()
    }

    render() {
        return (
            <div className="fixed-action-btn">
                <a className="btn-floating btn-large modal-trigger pulse" data-target="whatsnew_modal"
                   style={{backgroundColor: "#f86a6d"}}>
                    <i className="large material-icons white-text">question_mark</i>
                </a>
                <div id="whatsnew_modal" className="modal bottom-sheet">
                    <div className="modal-content">
                        <h4>{c("LOGIN.NEWS.TITLE")}</h4>
                        <p style={{whiteSpace: "pre-wrap"}}>
                            {c("LOGIN.NEWS.TEXT")}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}