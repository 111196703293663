import React from 'react';
import NavBar from "./NavBar";
import UserDropdown from "./UserDropdown";
import {capitalize, t as t_i18n} from '../../I18n/i18n'

export default function ExternalDoctorNavbar({image_path, user, configuration, topbar_prop = ""}) {
    const t = (key) => capitalize(t_i18n("NAVBAR." + key))

    const {report} = configuration

    const components = [
        {component: <UserDropdown user={user}/>, dataCy: "UserDropdown"}
    ]

    const urls = [
        {name: t("CURRENT_ALERTS"), url: "/"},
        {name: t("SEARCH_PATIENT"), url: "/externes/recherche"},
    ]

    if (report) {
        urls.push({name: t("REPORTS"), url: "/externes/rapports/index"})
    }

    return <NavBar image_path={image_path} urls={urls} topbar_prop={topbar_prop} components={components}/>
}
