import React, {useEffect} from 'react';
import ScopedElement from "../../Shared/ScopedElement";
import {Role} from "../../Shared/Enums/Scope";
import {NavLink, Route, Routes} from "react-router-dom";
import {isLocked, LockingElement} from "../AppointmentValidation";
import {IS_OPTICIAN, JOB} from "../../Shared/Helper";
import Prescriptions from "./mesures_pages/Prescriptions";
import Reglement from "../../rdv_reglement/Reglement";
import FondYeux from "./FondYeux";
import QuestionsAlerte from "./mesure_components/Questions/QuestionsAlerte";
import Pathologies from "../../app_pathologies/Pathologies";
import WriteReport from "./mesure_components/WriteReport";
import Mesure from "./Mesure";
import {observer} from "mobx-react";
import {t} from "../../I18n/i18n"
import {MesureTabs, MesureTabsType} from "./MesureTabs";
import Reeducation from "./mesures_pages/Reeducation";

const AppointmentRoutes = observer(({orthoptist, fundus_key, act_nature}) => {
    const ROUTES = [
        {
            name: <span>{t("APPOINTMENT_ROUTES.QUESTIONNAIRE_TITLE")}</span>,
            shortcut: "Q",
            path: IS_OPTICIAN ? "/questions" : "/",
            alert: [
                LockingElement.QUESTION,
                LockingElement.STEREOSCOPY,
                LockingElement.DIABETES,
                LockingElement.AMBLIOPIA
            ],
            exclude_from_scope: [Role.OPTICIAN],
            hidden: false,
            element: <QuestionsAlerte/>,
            dataCy: "AppointmentQuestionsLink",
            tab: MesureTabsType.QUESTIONS
        },
        {
            name: <span>{t("APPOINTMENT_ROUTES.MEASURES_TITLE")}</span>,
            shortcut: "M",
            path: IS_OPTICIAN ? "/" : "/measures",
            alert: [
                LockingElement.LEFT_ACUITY,
                LockingElement.RIGHT_ACUITY,
                LockingElement.LEFT_TENSION,
                LockingElement.RIGHT_TENSION,
                LockingElement.REF,
                LockingElement.AUTOREF,
                LockingElement.GLASSES,
            ],
            exclude_from_scope: [],
            hidden: false,
            element: <Mesure/>,
            dataCy: "AppointmentMeasuresLink",
            tab: MesureTabsType.MEASURES
        },
        {
            name: JOB === Role.OPTICIAN ? <span>{t("APPOINTMENT_ROUTES.CONSENT_TITLE")}</span> : <span>{t("APPOINTMENT_ROUTES.IMAGERY_TITLE")}</span>,
            shortcut: JOB === Role.OPTICIAN ? "C" : "I",
            path: "/fond-oeil",
            alert: [
                LockingElement.FUNDUS,
            ],
            exclude_from_scope: [],
            hidden: false,
            element: <FondYeux/>,
            key: fundus_key,
            dataCy: "AppointmentEyePhotoLink",
            tab: MesureTabsType.FUNDUS
        },
        {
            name: <span>{t("APPOINTMENT_ROUTES.PATHOLOGY_TITLE")}</span>,
            shortcut: "A",
            path: "/pathologies",
            alert: [],
            exclude_from_scope: [Role.OPTICIAN],
            hidden: false,
            element: <Pathologies/>,
            dataCy: "AppointmentPathologyLink",
            tab: MesureTabsType.PATHOLOGY
        },
        {
            name: <span>{t("APPOINTMENT_ROUTES.PRESCRIPTION_TITLE")}</span>,
            shortcut: "P",
            path: "/prescriptions",
            alert: [
                LockingElement.PRESCRIPTION,
            ],
            exclude_from_scope: [],
            hidden: orthoptist?.is_screening,
            element: <Prescriptions/>,
            dataCy: "AppointmentPrescriptionLink",
            tab: MesureTabsType.PRESCRIPTION
        },
        {
            name: <span>{t("APPOINTMENT_ROUTES.REPORT_TITLE")}</span>,
            shortcut: "C",
            path: "/report",
            alert: [],
            exclude_from_scope: [Role.OPTICIAN],
            hidden: orthoptist?.is_screening,
            element: <WriteReport/>,
            dataCy: "AppointmentCRLink",
            tab: MesureTabsType.REPORT
        },
        {
            name: <span>{t("APPOINTMENT_ROUTES.PAYMENT_TITLE")}</span>,
            shortcut: "R",
            path: "/reglement",
            alert: [
                LockingElement.ACT,
            ],
            exclude_from_scope: [],
            hidden: false,
            element: <Reglement/>,
            dataCy: "AppointmentPaymentLink",
            tab: MesureTabsType.PAYMENT
        },
        {
            name: <span>{t("APPOINTMENT_ROUTES.REEDUCATION_TITLE")}</span>,
            shortcut: "R",
            path: "/reeducation",
            alert: [],
            exclude_from_scope: [],
            hidden: false,
            element: <Reeducation/>,
            dataCy: "AppointmentReeducationLink",
            tab: MesureTabsType.REEDUCATION
        }
    ]

    useEffect(() => {
        setShortcuts()
    }, []);

    const setShortcuts = () => {
        ROUTES.forEach((route, index) => {
            if (route.shortcut) {
                document.addEventListener('keydown', (e) => {
                    try {
                        if ((e.key.toUpperCase() === route.shortcut && e.ctrlKey && e.altKey)) {
                            document.querySelector(`[data-cy="${route.dataCy}"]`).click()
                        }
                    } catch (e) {
                        // do nothing
                    }
                });
            }
        });
        // [²] to go to next tab, shift + [²] go to previous tab
        document.addEventListener('keydown', (e) => {
            if (e.key === "²") {
                e.preventDefault()
                goToTab(e.shiftKey ? -1 : 1)
            }
        });
    }

    // -1 => go to last tab, if first tab => go to last tab
    // 1 => go to next tab, if last tab => go to first tab
    const goToTab = (int) => {
        const currentTab = document.querySelector(".tabs .active")
        const indexInRoutes = ROUTES.findIndex(route => route.dataCy === currentTab.dataset.cy)
        const nextIndex = indexInRoutes + int
        const nextTab = document.querySelector(`[data-cy="${ROUTES[nextIndex < 0 ? ROUTES.length - 1 : nextIndex % ROUTES.length].dataCy}"]`)
        nextTab.click()
    }

    const needAlert = (required) => {
        return isLocked(...required)
    }

    const renderNav = () => {
        return filterTabs().map((route, index) => {
            if (route.hidden) return null
            return <ScopedElement exclude={route.exclude_from_scope} key={"scoped-route"+index}>
                <li className="tab col s2" key={index}>
                    <NavLink data-cy={route.dataCy}
                             className={needAlert(route.alert) ? "red-text" : "black-text"}
                             to={route.path}>{route.name}</NavLink>
                </li>
            </ScopedElement>
        })
    }

    const filterTabs = () => {
        const tabs = MesureTabs[act_nature]
        return tabs.map(tab => ROUTES.find(route => route.tab === tab))
    }

    const renderRoutes = () => {
        return filterTabs().map((route, index) => {
            return <Route path={route.path} element={route.element} key={"route-"+route.key}/>

        })
    }

    return <>
        <div className="row" style={{margin: 0}}>
            <div className="col s12">
                <ul className="tabs tabs-fixed-width transparent">
                    {renderNav()}
                </ul>
            </div>
        </div>
        <Routes>
            {renderRoutes()}
        </Routes>
    </>
})

export default AppointmentRoutes;