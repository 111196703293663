import React from "react";
import PatientInfo from "./PatientInfo";
import ExamHistory from "./ExamHistory";
import PatientSideBar from "../../patient_data/SideBar/PatientSideBar";
import HelpFAB from "../../Shared/Help/HelpFAB";

export default function Overview({data, cle, orthoptiste_signed, ophtalmologiste_signed, secretaire_signed, level_2}) {

    return <>
        <div id="full-height" className="row" style={{padding: 20, gap: 20}}>
            <div className="col s12 l4" style={{height: "100%"}}>
                <div style={{display: "flex", gap: 20, flexDirection: "column", height: "100%"}}>
                    <PatientInfo
                        style={{height: "60%"}}
                        patient={data.patient}
                        office_name={data.office_name}
                        cle={cle}
                        orthoptiste_signed={orthoptiste_signed}
                        ophtalmologiste_signed={ophtalmologiste_signed}
                        secretaire_signed={secretaire_signed}/>
                    <PatientSideBar style={{height: "40%"}} patient={data.patient}/>
                </div>
            </div>
            <div className="col s12 l8" style={{height: "100%"}}>
                <ExamHistory
                    data={data}
                    orthoptiste_signed={orthoptiste_signed}
                    level_2={level_2}/>
            </div>

        </div>
        <HelpFAB url="https://www.temeoo.fr/documentation#fiche-patient" position="bottom-left"/>
    </>
}