import socket from "../../Shared/socket";
import NIDEK_RT_2100_FSM, {PRE_NIDEK_RT_2100} from "../StateMachine/Nidek/NIDEK_RT_2100_FSM";
import NIDEK_RT_3100_FSM, {PRE_NIDEK_RT_3100} from "../StateMachine/Nidek/NIDEK_RT_3100_FSM";
import NIDEK_RT_5100_FSM, {PRE_NIDEK_RT_5100} from "../StateMachine/Nidek/NIDEK_RT_5100_FSM";

/*
usb engines send an object :
{
    data: string to send,
    waitForResponse: integer, number of seconds to wait for a return on the serial port , 0 means no wait
}
 */

export default function UsbEngines(machine, measures) {
    const serialWriter = (data) => {
        socket.emit("web-to-desktop",
            {
                type: "USB",
                data: data,
                target: machine,
                machine: socket.bridge_uid
            })
    }
    switch (machine) {
        case "Nidek RT-2100":
            return new NIDEK_RT_2100_FSM(measures, serialWriter);
        case "Nidek RT-3100":
            return new NIDEK_RT_3100_FSM(measures, serialWriter);
        case "Nidek RT-5100":
            return new NIDEK_RT_5100_FSM(measures, serialWriter);
        case "Pre Nidek RT-2100":
            return {data: PRE_NIDEK_RT_2100(), waitForResponse: 2000};
        case "Pre Nidek RT-3100":
            return {data: PRE_NIDEK_RT_3100(), waitForResponse: 2000};
        case "Pre Nidek RT-5100":
            return {data: PRE_NIDEK_RT_5100(), waitForResponse: 2000};
        default:
            return null;
    }
}