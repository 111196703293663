import React from "react"
import Dropzone from 'react-dropzone'
import axios from "axios"
import {observer} from "mobx-react"
import MesureStore from './MesureStore'
import ActionMedia from "../../Shared/ActionMedia";
import ImageSelectionModal from "../../Shared/ImageSelectionModal"
import {JOB} from "../../Shared/Helper";
import {Role} from "../../Shared/Enums/Scope";
import ScopedElement from "../../Shared/ScopedElement";
import {RequestState} from "../../Shared/StateHelper";
import OverLoader from "../../Shared/OverLoader";
import {toast} from "react-toastify";
import {t} from "../../I18n/i18n"


@observer
class FondYeux extends React.Component {
    constructor(props) {
        super(props);
        this.loadImages = this.loadImages.bind(this)
        this.loadVideos = this.loadVideos.bind(this)
        this.purgeImages = this.purgeImages.bind(this)
        this.purgeSingleImage = this.purgeSingleImage.bind(this)
        this.purgeSingleVideo = this.purgeSingleVideo.bind(this)
        this.changeGauche = this.changeGauche.bind(this)
        this.changeDroit = this.changeDroit.bind(this)
        this.state = {
            images: [],
            videos: [],
            pdfs: [],
            possibilites: [],
            droit: "",
            gauche: "",
            previousAppointments: [],
            isLoaded: RequestState.IDLE
        };
    }

    componentDidMount() {
        this.loadImages()
        MesureStore.hasImages()

        axios.get("/api/appointements/PreviousAppointements", {params: {appointment_id: MesureStore.appointement_id}})
            .then((data) => {
                this.setState({
                    previousAppointments: data.data.appointments_list,
                    selectedAppointment: data.data.appointments_list[0]?.id
                })
            })

        axios.get("/api/getYeuxStatus", {params: {appointment_id: MesureStore.appointement_id}}).then((data) => {
            if (data.data[1]["id"] !== null) {
                this.setState({
                    possibilites: data.data[0],
                    droit: data.data[1]["droit"],
                    gauche: data.data[1]["gauche"],
                })
            } else {
                this.setState({
                    possibilites: data.data[0],
                    droit: "Bon",
                    gauche: "Bon"
                })
            }
        })
        this.setState({isLoaded: RequestState.SUCCESS})
    }

    changeGauche(e) {
        axios.post("/api/updateYeux", {
            gauche: e.target.value,
            droit: this.state.droit,
            appointment_id: MesureStore.appointement_id
        }).then((data) => {
            this.setState({droit: data.data["droit"], gauche: data.data["gauche"]})
        })
    }

    changeDroit(e) {
        axios.post("/api/updateYeux", {
            droit: e.target.value,
            gauche: this.state.gauche,
            appointment_id: MesureStore.appointement_id
        }).then((data) => {
            this.setState({droit: data.data["droit"], gauche: data.data["gauche"]})
        })
    }

    purgeImages() {
        axios.post("/api/purgeImages", {appointment_id: MesureStore.appointement_id}).then(() => {
            MesureStore.hasImages()
            this.setState({images: []})
            MesureStore.media = {...MesureStore.media, images: []}
        })
    }

    purgeSingleImage(image_id) {
        axios.delete("/api/image/" + image_id, {params: {appointment_id: MesureStore.appointement_id}}).then(() => {
            const files_array = this.state.images.filter((fileObject => fileObject["img_id"] !== image_id))
            this.setState({images: files_array}, () => MesureStore.hasImages())
            MesureStore.media = {...MesureStore.media, images: files_array.map(image => image.img)}
        })
    }

    purgeSingleVideo(video_id) {
        axios.delete("/api/video/" + video_id, {params: {appointment_id: MesureStore.appointement_id}}).then(() => {
            let files_array = this.state.videos.filter((fileObject => {
                return fileObject["video_id"] !== video_id
            }))
            this.setState({videos: files_array})
            MesureStore.media = {...MesureStore.media, videos: files_array.map(video => video.video)}

        })
    }

    loadVideos() {
        axios.get("/api/getVideos", {params: {appointment_id: MesureStore.appointement_id}}).then((data) => {
            this.setState({videos: data.data.videos})
            MesureStore.media = {...MesureStore.media, videos: data.data.videos.map(video => video.video)}
        })
    }

    loadImages() {
        axios.get("/api/images", {params: {appointment_id: MesureStore.appointement_id}}).then((resp) => {
            this.setState({images: resp.data.images, pdfs: resp.data.pdfs}, () => MesureStore.hasImages())
            MesureStore.media = {...MesureStore.media, images: resp.data.images.map(image => image.img)}
        })
        this.loadVideos()
    }

    onUpload = (files) => {

        this.setState({isLoaded: RequestState.RELOADING})

        let formData = new FormData();
        let formDataVideo = new FormData();
        let formDataPdf = new FormData();
        let hasImage = false;
        let hasVideo = false;
        let hasPdf = false;
        files.forEach(file => {
            if (file.name.includes(".mp4") || file.name.includes(".MP4")) {
                formDataVideo.append(file.name, file, file.name)
                hasVideo = true
            } else if (file.name.includes(".jpg") || file.name.includes(".jpeg") || file.name.includes(".JPG") || file.name.includes(".JPEG") || file.name.includes(".png") || file.name.includes(".PNG")) {
                formData.append(file.name, file, file.name)
                hasImage = true
            } else if(file.name.includes(".pdf") || file.name.includes(".PDF")){
                formDataPdf.append(file.name, file, file.name)
                hasPdf = true
            } else {
                this.setState({isLoaded: RequestState.ERROR})
            }
        })
        if (hasVideo) {
            axios.post("/api/uploadVideos", formDataVideo)
                .then(() => {
                    this.setState({isLoaded: RequestState.SUCCESS}, this.loadVideos)
                }).catch(() => {
                toast.error("Erreur lors de du téléchargement des vidéos")
                this.setState({isLoaded: RequestState.SUCCESS})
            })
        }
        if (hasImage) {
            axios.post("/api/uploadImages", formData)
                .then((data) => {
                    let new_data = []
                    /*
                    for (let i = 0; i < data.data.images_data.length; i++) {
                        new_data.push({"img_id": data.data.images_data[i]["id"], "img": files[i].preview})
                    }
                    let joined = this.state.images.concat(new_data) */
                    this.setState({isLoaded: RequestState.SUCCESS})
                    this.loadImages();
                }).catch(() => {
                toast.error("Erreur lors de du téléchargement des images")
                this.setState({isLoaded: RequestState.SUCCESS})
            })
                .then(() => MesureStore.hasImages())
        }
        if(hasPdf){
            axios.post("/api/uploadPdfs", formDataPdf)
                .then(() => {
                    this.setState({isLoaded: RequestState.SUCCESS}, this.loadImages)
                }).catch(() => {
                toast.error("Erreur lors de du téléchargement des pdfs")
                this.setState({isLoaded: RequestState.SUCCESS})
            })
        }
    }

    checkImagesBeforeAttaching = (images) => {
        if (!Array.isArray(images)) {
            images = new Array(images)
        }
        this.attachFilesToCurrentAttachement(images)
    }

    attachFilesToCurrentAttachement = (images) => {

        let currentImagesId = this.state.images.map(image => image["img_id"])

        let filtered_images = images.filter((image) => {
            return !currentImagesId.includes(image["img_id"])
        })

        if (filtered_images.length > 0) {
            axios.patch("/api/appointements/attachFiles", {
                images_data: filtered_images,
                appointment_id: MesureStore.appointement_id
            }).then((resp) => {
                this.setState({images: resp.data.images_data}, () => MesureStore.hasImages())
                MesureStore.media = {...MesureStore.media, images: resp.data.images_data.map(image => image.img)}
            })
        }
    }


    render() {
        const dropZone = {
            backgroundColor: 'grey lighten-3',
            border: 'dashed #A9A9A9 2px',
            cursor: "pointer",
            width: "15vw",
            height: "23vh"
        }

        return <div className="app row" style={{margin: 20, gap: 20, overflowY: "scroll"}}>
            <div className="col s2 center">
                <h2 className="center-align" style={{whiteSpace: "nowrap"}}>{t("EYE_BASE.DEPOSIT")}</h2>
                <Dropzone accept="" onDrop={this.onUpload} style={dropZone} data-cy="FileDropZone">
                    {({getRootProps, getInputProps}) => (
                        <section>
                            <div {...getRootProps()}>
                                <input data-cy="InputDropZone" {...getInputProps()} />
                                <div className="valign-wrapper"
                                     style={{height: 200, justifyContent: "center", border: "dashed 1px black"}}>
                                    <i className="material-icons medium">get_app</i>
                                </div>
                            </div>
                        </section>
                    )}
                </Dropzone>

                {this.state.previousAppointments.length ?
                    <div style={{margin: 10}}>
                        <ImageSelectionModal
                            previousAppointments={this.state.previousAppointments}
                            checkImagesBeforeAttaching={this.checkImagesBeforeAttaching}
                        />
                    </div> : null}

            </div>
            <div className={`col s8 center`}>
                <h2 className="center">
                    {JOB === Role.OPTICIAN ? t("EYE_BASE.INFO_AND_CONSENT") : t("IMAGES")}
                </h2>
                <OverLoader state={this.state.isLoaded}
                            text={t("EYE_BASE.DISPLAYED_HERE")} textColor="white-text">
                    <div className="row"
                         style={{backgroundColor: "black", borderRadius: "20px", minHeight: "23vh"}}>
                        {this.state.images.length ? (this.state.images.map((img, index) => (
                            <div className="col s6" key={index}
                                 data-cy="ApppointmentMedia"
                                 style={{display: "flex", justifyContent: "center", padding: "1vh"}}>
                                <ActionMedia
                                    src={img}
                                    magnifiable
                                    onClick={this.purgeSingleImage}
                                    position="top-right"
                                    iconColor="red"
                                    imgWidth="15vw"
                                    icon="close"
                                >
                                </ActionMedia>
                            </div>
                        ))) : null
                        }
                        {this.state.videos.length ? (this.state.videos.map((video, index) => {
                            return <div className="col s6" key={index}
                                        style={{display: "flex", justifyContent: "center", padding: "1vh"}}>
                                <ActionMedia
                                    src={video}
                                    video
                                    onClick={this.purgeSingleVideo}
                                    position="top-right"
                                    iconColor="red"
                                    imgWidth="15vw"
                                    icon="close"
                                >
                                </ActionMedia>
                            </div>
                        })) : null}
                    </div>
                </OverLoader>
                {this.state.pdfs.length > 0 ? (
                    <div>
                        <h3 className="center">{t("EYE_BASE.REPORT_PDF")}</h3>
                        {this.state.pdfs.map((pdf, index) => {
                            return (
                                <div key={index}>
                                    <iframe src={pdf.pdf} frameborder="0"
                                            style={{width: "40vw", minHeight: "50vh"}}></iframe>

                                </div>
                            )
                        })}
                    </div>
                ) : null}
            </div>
            {/*ETAT DE L'OEIL*/}
            {/*<ScopedElement exclude={[Role.OPTICIAN]}>*/}
            {/*    <div className="col s2">*/}
            {/*        <h3 className="center">État de l'oeil</h3>*/}
            {/*        <div className="input-field">*/}
            {/*            <h4 className="center">Droit</h4>*/}
            {/*            <select id="etat-droit" value={this.state.droit} onChange={this.changeDroit}*/}
            {/*                    className="browser-default">*/}
            {/*                <option value="" disabled>Etat Droit</option>*/}
            {/*                {this.state.possibilites.map((poss, index) => (*/}
            {/*                    <option key={index} value={poss}>{poss}</option>*/}
            {/*                ))}*/}
            {/*            </select>*/}
            {/*        </div>*/}
            {/*        <div className="input-field">*/}
            {/*            <h4 className="center">Gauche</h4>*/}
            {/*            <select id="etat-gauche" onChange={this.changeGauche} value={this.state.gauche}*/}
            {/*                    className="browser-default">*/}
            {/*                <option value="" disabled>Etat Gauche</option>*/}
            {/*                {this.state.possibilites.map((poss, index) => (*/}
            {/*                    <option key={index} value={poss}>{poss}</option>*/}
            {/*                ))}*/}
            {/*            </select>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</ScopedElement>*/}
            <div className="col s2">
                <h3 className="center">{t("ACTIONS")}</h3>
                <p className="center"><a onClick={this.purgeImages}
                                         className="red darken-2 btn z-depth-0"><i
                    className="material-icons left"
                    data-cy="DeleteAppointmentMedia">delete</i>{t("EYE_BASE.DELETE_ALL")}</a></p>
            </div>
        </div>
    }
}

export default FondYeux
