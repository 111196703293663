import React from "react";

const _ = require("lodash");
const DocumentImg = ({appointment}) => {
    if (_.isEmpty(appointment?.image)) return null
    return <div
        style={{
            display: "flex",
            flexDirection: "row",
            marginBlock: 20,
            backgroundColor: "black",
            padding: 20,
            borderRadius: 20,
        }}
    >
        <div className="row" style={{width: "100%"}}>
            {appointment?.image.map((img, index) => {
                return <div className="col s6" style={{display: "flex", justifyContent: "center"}}>
                    <img style={{width: "300px", float: "left"}} src={img} alt={"image n°" + (index + 1)}/>
                </div>
            })}
        </div>

    </div>
};

export default DocumentImg;
