import React from 'react'
import {observer} from "mobx-react";
import {MedicalEventStatus} from "../admin/Console/MedicalEvents/Enums/Enums";
import MedicalEventStore from "./MedicalEventStore";
import Validator from "../Shared/Validator";
import {t} from "../I18n/i18n";
import Helper from "../Shared/Helper";
import ScopedElement from "../Shared/ScopedElement";
import {Role} from "../Shared/Enums/Scope";

const _ = require('lodash')
const authorizedRoles = [
    Role.ORTHOPTIST,
    Role.OPHTALMOLOGIST,
    Role.SUPER_ADMIN,
    Role.ADMIN,
    Role.AUDITOR
]
const MedicalEventHeader = observer(() => {

    const renderStatusBadge = () => {
        let data = {}
        switch (MedicalEventStore.event.status) {
            case MedicalEventStatus.SCHEDULED:
                data = {color: "blue", text: "Planifié"}
                break
            case MedicalEventStatus.IN_PROGRESS:
                data = {color: "green", text: "En cours"}
                break
            case MedicalEventStatus.COMPLETED:
                data = {color: "grey", text: "Terminé"}
                break
            case MedicalEventStatus.CANCELED:
                data = {color: "red", text: "Annulé"}
                break
            default:
                return null
        }
        return <span className={`badge ${data.color} darken-2 white-text`}
                     style={{borderRadius: 3, fontWeight: "bold"}}>
            {data.text}
        </span>
    }

    const renderCoreHeader = () => {
        let date = Helper.formatDate(MedicalEventStore.event.date, "DD/MM/YYYY HH:MM")
        if (_.isEmpty(date)) date = "À définir"
        return <>
            {renderStatusBadge()}
            <h6 style={{margin: 0}}>{Helper.capitalizeFirstLetter(MedicalEventStore.event.name)}</h6>
            <h6 style={{margin: 0}}>Début : {date}</h6>
        </>
    }

    const renderStartButton = () => {
        return <Validator onValidation={() => MedicalEventStore.startEvent()}
                          text={"Débuter l'évènement ?"}
                          detail={"En débutant l'évènement, vous donnez la possibilité aux participants de remplir les dossiers patients"}
                          validationText={t("START")} validationColor="blue darken-2" validationIcon="play_arrow"
                          abortColor="black darken-2" abortIcon="cancel" abortText={t("CANCEL")}
                          id={`start_event_validator`}>
            <a className="btn-small blue darken-2 z-depth-0" style={{marginLeft: "auto", margin: 0}}>
                <i className="material-icons left">play_arrow</i>
                Débuter l'évènement
            </a>
        </Validator>
    }

    const renderCancelButton = () => {
        return <Validator onValidation={() => MedicalEventStore.cancelEvent()}
                          text={"Annuler l'évènement ?"}
                          detail={"En annulant l'évènement, vous empêchez toute action sur les dossiers patients et les rapports"}
                          validationText={t("CANCEL")} validationColor="red darken-2" validationIcon="cancel"
                          abortColor="black darken-2" abortIcon="cancel" abortText={t("CANCEL")}
                          id={`cancel_event_validator`}>
            <a className="btn-small red darken-2 z-depth-0" style={{marginLeft: "auto", margin: 0}}>
                <i className="material-icons left">cancel</i>
                Annuler l'évènement
            </a>
        </Validator>
    }

    const renderCompleteButton = () => {
        return <Validator onValidation={() => MedicalEventStore.completeEvent()}
                          text={"Terminer l'évènement ?"}
                          detail={"En cloturant l'évènement, tous les dossiers apparaitront dans les dossiers de l'ophtalmologiste lecteur."}
                          validationText={t("END")} validationColor="blue darken-2" validationIcon="stop"
                          abortColor="black darken-2" abortIcon="cancel" abortText={t("CANCEL")}
                          id={`end_event_validator`}>
            <a className="btn-small blue darken-2 z-depth-0" style={{marginLeft: "auto", margin: 0}}>
                <i className="material-icons left">stop</i>
                Terminer l'évènement
            </a>
        </Validator>
    }

    const renderRestartButton = () => {
        return <Validator onValidation={() => MedicalEventStore.startEvent()}
                          text={"Reprendre l'évènement ?"}
                          warning
                          detail={"Attention : si certains examens ont été relus, leur statut sera réinitialisé et ils devront être relus à nouveau."}
                          validationText={t("RELAUNCH")} validationColor="blue darken-2" validationIcon="replay"
                          abortColor="black darken-2" abortIcon="cancel" abortText={t("CANCEL")}
                          id={`restart_event_validator`}>
            <a className="btn-small blue darken-2 z-depth-0" style={{marginLeft: "auto", margin: 0}}>
                <i className="material-icons left">replay</i>
                Relancer l'évènement
            </a>
        </Validator>
    }

    const renderScheduled = () => {
        return <>
            {renderCoreHeader()}
            <h6 className="center" style={{flex: 1}}>
                L'évènement n'a pas encore demarré, vous pouvez ajouter et pré-remplir des dossiers patients en
                attendant le jour-J
            </h6>
            <ScopedElement scope={authorizedRoles}>
                {renderStartButton()}
                {renderCancelButton()}
            </ScopedElement>
        </>
    }

    const renderInProgress = () => {
        return <>
            {renderCoreHeader()}
            <h6 className="center" style={{flex: 1, fontWeight: 600}}>
                L'évènement est en cours, vous pouvez remplir les dossiers patients
            </h6>
            <ScopedElement scope={authorizedRoles}>
                {renderCompleteButton()}
                {renderCancelButton()}
            </ScopedElement>
        </>
    }

    const renderCompleted = () => {
        return <>
            {renderCoreHeader()}
            <h6 className="center" style={{flex: 1}}>
                L'évènement est terminé, vous pouvez consulter les rapports des patients
            </h6>
            <ScopedElement scope={authorizedRoles}>{renderRestartButton()}</ScopedElement>
        </>
    }

    const renderCanceled = () => {
        return <>
            {renderCoreHeader()}
            <h6 className="center" style={{flex: 1}}>
                L'évènement a été annulé, vous pouvez en organiser un nouveau ou relancer celui-ci
            </h6>
            <ScopedElement scope={authorizedRoles}>{renderRestartButton()}</ScopedElement>
        </>
    }

    const renderHeaderBody = () => {
        switch (MedicalEventStore.event.status) {
            case MedicalEventStatus.SCHEDULED:
                return renderScheduled()
            case MedicalEventStatus.IN_PROGRESS:
                return renderInProgress()
            case MedicalEventStatus.COMPLETED:
                return renderCompleted()
            case MedicalEventStatus.CANCELED:
                return renderCanceled()
            default:
                return null
        }
    }

    return <div className="card" style={{margin: 0, borderRadius: 0, paddingBlock: 5}}>
        <div className="card-body valign-wrapper" style={{gap: 20}}>
            {renderHeaderBody()}
        </div>
    </div>


})

export default MedicalEventHeader