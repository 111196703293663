import React, {Component} from 'react';
import axios from "axios";
import WhatsNew from "./WhatsNew";
import HelpButton from "../Shared/HelpButton";
import Pincode from "../Shared/Pincode/Pincode";
import Toastr from "../Shared/Toastr";
import {c, t} from "../I18n/i18n";

export default class LoginPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            email: "",
            password: "",
            rememberMe: false,
            errorMsg: "",
            otp: false,
            otp_pin: "",
            display_pincode: true,
            otp_code: "",
            backup_code: "",
        }
    }

    onKeyDown(e) {
        if (e.key === "Enter") {
            this.login()
        }
    }

    componentDidMount() {
        let els = document.getElementsByClassName("input")
        Array.prototype.forEach.call(els, el => el.style.backgroundColor = "red")
        let csrfToken = document.querySelector("meta[name='csrf-token']").content
        axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
        document.addEventListener("keydown", this.onKeyDown.bind(this))
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyDown.bind(this))
    }

    login = () => {
        axios.post("/login_and_otp", {
            email: this.state.email,
            password: this.state.password,
            remember_me: this.state.rememberMe,
            otp_pin: this.state.otp_pin,
            backup_code: this.state.backup_code

        }).then(resp => {
            let data = resp.data
            if (data.status === "LOGIN_DONE") {
                location.reload();
            } else if (data.status === "ERR") {
                this.setState({
                    errorMsg: c("LOGIN.INVALID_CREDENTIALS"),
                    loading: false

                })
            } else if (data.status === "pending_otp") {
                this.setState({
                    otp: true,
                    loading: false,
                    errorMsg: ""
                })
            }
        }).catch(err => {
            if (err.response.status === 400) {
                this.setState({
                    errorMsg: t("LOGIN.INVALID_CREDENTIALS"),
                })
            }
        })
    }

    handlePin = (value) => {
        this.setState({otp_pin: value}, () => {
            if (this.state.otp_pin.length === 6) this.login()
        })
    }

    renderLogin() {
        return (
            <div className="container">
                <h1 className="center" id="main-title">{c("LOGIN.WELCOME")}</h1>
                <form className="card center-align" onSubmit={this.login} style={{maxWidth: "500px", margin: "auto"}}>
                    <div className="input-field outlined">
                        <input id="email" type="email"
                               value={this.state.email}
                               className="validate"
                               data-cy="EmailInput"
                               onChange={(e) => this.setState({email: e.target.value})}/>
                        <label htmlFor="email">{c("EMAIL")}</label>
                    </div>
                    <br/>
                    <div className="input-field outlined">
                        <input id="pass"
                               type="password"
                               value={this.state.password}
                               className="validate"
                               data-cy="PasswordInput"
                               onChange={(e) => this.setState({password: e.target.value})}
                        />
                        <label htmlFor="pass">{c("PASSWORD")}</label>
                    </div>
                    <p className="red-text" data-cy="error_login">{this.state.errorMsg}</p>
                    <p className="left-align">
                        <label>
                            <input type="checkbox" id="rememberMe"
                                   onChange={() => this.setState({rememberMe: document.getElementById("rememberMe").checked})}/>
                            <span>{c("LOGIN.REMEMBER ME")}</span>
                        </label>
                    </p>
                    <a className="btn z-depth-0"
                       type="submit"
                       style={{backgroundColor: "#f86a6d"}}
                       data-cy="ConnexionButton"
                       onClick={this.login}>{c("LOGIN.CONNECT")}</a>
                </form>
            </div>
        )
    }

    renderOtp() {
        return (
            <React.Fragment>
                {this.state.loading ? this.renderLoader() : (<div/>)}
                <h1 className="center" id="main-title">{t("LOGIN.2FA.TITLE")}</h1>
                {this.state.display_pincode ? <div className="valign-wrapper" style={{justifyContent: "center"}}>
                    <h6 className="center" style={{margin: 0}}>{c("LOGIN.2FA.ENTER_CODE")}</h6>
                    <HelpButton text={t("LOGIN.2FA.HELP")}
                                videoSrc={"/videos/authent_2FA.mp4"}
                                height="50vh"
                                size="small"/>
                </div> : null}
                <div style={{display: "flex", justifyContent: "center"}}>
                    {this.state.display_pincode ?
                        <Pincode onChange={this.handlePin}/>
                        : <div className="input-field col s12 outlined">
                            <h6>{c("LOGIN.USE_RECOVERY")}:</h6>
                            <input
                                id="title"
                                type="text"
                                placeholder={t("LOGIN.2FA.ENTER_RECOVERY")}
                                onChange={(e) => this.setState({backup_code: e.target.value, errorMsg: ""})}
                            />
                        </div>}
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1.25vh",
                    flexDirection: "column",
                    alignItems: "center"
                }}>
                    <span style={{cursor: "pointer"}}
                          onClick={() => this.setState({display_pincode: !this.state.display_pincode, errorMsg: ""})}>
                    {this.state.display_pincode ? c("LOGIN.2FA.ACCESS_LOST")+"." : c("LOGIN.2FA.ACCESS_OK")}
                    </span>
                    <p className="red-text" data-cy="error_login">{this.state.errorMsg}</p>
                    <a className={`btn blue darken-2 z-depth-0 ${this.state.otp_pin.length === 6 || this.state.backup_code.length === 6 ? "" : "disabled"}`}
                       onClick={this.login}
                       style={{width: "fit-content"}}
                    >
                        {c("LOGIN.CONNECT")}
                    </a>
                </div>

            </React.Fragment>
        )
    }

    renderLoader() {
        return (
            <div className="progress" style={{margin: 0}}>
                <div className="indeterminate"></div>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.state.otp ? (<div/>) : this.renderLogin()}
                {(this.state.otp && !this.state.loading) ? this.renderOtp() : (<div/>)}
                {this.props.brand !== "WHITE" ? (<WhatsNew/>) : (<div/>)}
                <Toastr/>
            </div>
        )
    }
}
