//import socket

import socket from "../Shared/socket";
import SharedFolder from "./Mesures/SharedFolder";
import UsbEgines from "./Mesures/UsbEgines";
import axios from "axios";
import {toast} from "react-toastify";
import {TypesMesures} from "../Shared/Enums/Enums";
class WebToDesktop {
    static fsms = []
    constructor() {
        var self = this;
        socket.on("web-to-desktop-response", (data) => {
          if(data.success === false){
              toast.error(data.message)
          }else{
            WebToDesktop.fsms.forEach((fsm) => {
                if(!fsm.next(data.message)){
                    WebToDesktop.fsms = WebToDesktop.fsms.filter((f) => f !== fsm);
                }
            })
          }
        })

    }
    initialize_shorcuts(mesureStore){
         axios.get("/web_to_desktop/receivers").then((data)=>{
            let receivers  = data.data.receivers;
            // for each receiver add a listener on the keys CTRL + SHIFT + index
            receivers.forEach((receiver, index) => {
                window.addEventListener("keydown", (e) => {
                    e = e || window.event;
                    if(e.ctrlKey && e.shiftKey && e.keyCode === 48 + index+1){
                        console.log("sending",mesureStore.existingMesures)
                        WebToDesktop.sendMeasures(mesureStore.existingMesures,receiver);
                    }
                })

                // add a button and listener to the page, position fixed bottom left
                let button = document.createElement("button");
                button.style.position = "fixed";
                button.style.bottom = "0";
                button.style.left = "0";
                button.style.zIndex = "1000";
                button.style.padding = "10px";
                button.style.borderRadius = "10px";
                button.style.backgroundColor = "#ecf0f1";
                button.style.color = "#7f8c8d";
                button.style.border = "none";
                button.style.outline = "none";
                button.style.cursor = "pointer";
                button.style.fontWeight = "bold";
                button.style.fontSize = "12px";
                button.innerText = "Envoi sur "+receiver.engine;
                document.body.appendChild(button);
                button.addEventListener("click", () => {
                    WebToDesktop.sendMeasures(mesureStore.existingMesures,receiver);

                });

                // send automatically on receive of data
              /*
              ca bug pour l instant , a voir plus tard
              mesureStore.addListenerOnRefresh(() => {
                    // avoid reflection
                    if(mesureStore.existingMesures.some((mesure) => mesure.titre === TypesMesures.REF_SUBJ)){
                        return;
                    }
                    WebToDesktop.sendMeasures(mesureStore.existingMesures,receiver);
                })

               */
            })

        })
    }
    static sendMeasures(measures, receiver ) {
        let data = SharedFolder(receiver.engine, measures);
        if(data !== null){
            socket.emit('web-to-desktop', {
                type: "XML",
                data: data,
                file: receiver.path+"/output.xml",
                machine: socket.bridge_uid
            });
            return;
        }
        let fsm = UsbEgines(receiver.engine, measures);
        if(fsm !== null){
            fsm.next();
            WebToDesktop.fsms.push(fsm);
        }
    }

    static getReceivers(){
        socket.emit('web-to-desktop', {
            type: "GET-RECEIVERS",
            machine: socket.bridge_uid
        });
    }
    static getConnectedUsbDevices(){
        socket.emit('web-to-desktop', {
            type: "GET-USB-CONNECTED-DEVICES",
            machine: socket.bridge_uid
        });
    }
}
export default WebToDesktop;