import React, {useEffect, useState} from "react";
import axios from "axios";
import {Link} from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import moment from "moment";

export default function PaiementsErrors(){

    const [page, setPage] = useState(1);
    const [errors, setErrors] = useState([]);

    useEffect(() => {
        loadData(page);
    }, []);
    const loadData = (page)=> {
        axios.get("/admin/console/bills/paiement_errors?page="+page).then((data)=>{
            setErrors(errors.concat(data.data.data))
        })
        setPage(page + 1);
    }
    const fetchMoreData = () => {
        loadData(page);
    }

    return (
        <div>
            <InfiniteScroll
                dataLength={errors.length}
                next={fetchMoreData}
                hasMore={true}
                loader={<h4 className="center">Chargement...</h4>}
            >
                <table className="striped bordered">
                    <thead>
                    <tr>
                        <th> Date </th>
                        <th> Message </th>
                        <th>Paiement </th>
                        <th>details</th>
                    </tr>
                    </thead>
                    <tbody>
                    {errors.map((i, index) => (
                        <tr key={index}>
                            <td>{moment(i.created_at).format("DD-MM-YYYY h:mm")}</td>
                            <td>{i.message}</td>
                            <td>{i.paiement_id}</td>
                            <td><Link to={"/paiement_errors/"+i.id}>
                                <i className="material-icons">visibility</i>
                            </Link></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </InfiniteScroll>

        </div>
    )

}