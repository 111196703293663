import {RequestState} from "../../../../../Shared/StateHelper";
import {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";

const useOffice = (filter) => {
    const [offices, setOffices] = useState([]);
    const [requestState, setRequestState] = useState(RequestState.IDLE);

    useEffect(() => {
        getOffices();
    }, [filter.billingCategory]);

    const getOffices = () => {
        setRequestState(RequestState.LOADING);
        axios.get('/admin/console/users/office/all', {
            params: {
                filter: {
                    billing_category_id: filter.billingCategory?.id
                }
            }
        }).then((resp) => {
            setOffices(resp.data);
            setRequestState(RequestState.SUCCESS);
        }).catch((err) => {
            setRequestState(RequestState.ERROR);
            toast.error("Impossible de récupérer les cabinets")
        })
    };

    const create = (office) => {
        setRequestState(RequestState.LOADING);
        return axios.put('/admin/console/users/office', {
            office: office
        }).then((resp) => {
            setOffices([...offices, resp.data])
            setRequestState(RequestState.SUCCESS)
        }).catch((err) => {
            setRequestState(RequestState.ERROR)
            if (err.response.status === 400) {
                toast.error("Ce nom existe déjà")
            } else if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de créer le cabinet")
        })
    }

    const update = (office) => {
        return axios.patch(`/admin/console/users/office/${office.id}`, {
            office: office
        }).then((resp) => {
            setOffices(offices.map(user => user.id === office.id ? resp.data : user))
            setRequestState(RequestState.SUCCESS)
            return resp
        }).catch((err) => {
            setRequestState(RequestState.ERROR)
            if (err.response.status === 401) {
                toast.error("Vous n'avez pas les droits pour effectuer cette action")
            } else toast.error("Impossible de modifier le cabinet")
        })
    }

    return {offices, state: requestState, create, update};
};

export default useOffice;