import React, {Component} from 'react';
import {c, u} from "../I18n/i18n";
import HelpIcon from "../Shared/Help/HelpIcon";

export default class AskFor2FA extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const elems = document.getElementById("2FA_modal");
        M.Modal.init(elems, {});
        M.Modal.getInstance(document.getElementById("2FA_modal")).open()
    }

    componentWillUnmount() {
        M.Modal.getInstance(document.getElementById("2FA_modal")).destroy()
    }

    render() {
        return (
            <div>
                <div id="2FA_modal" className="modal">
                    <div className="modal-content">
                        <div className="center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                            <h1 className="red-text" style={{ marginTop: 40 }}>{u("WARNING")}</h1>
                            <HelpIcon url="https://www.temeoo.fr/documentation#2FA"/>
                        </div>
                        <p>{c("2FA.POPIN.TEXT", {app: this.props.software})}</p>
                        <p>{c("2FA.POPIN.HOW_TO")}</p>
                    </div>
                    <div className="modal-footer center-align">
                        <a href="/orthoptistes/administration#/two_factor_auth" className="btn-flat">{c("I_DO_IT")}</a>
                    </div>
                </div>
            </div>
        )
    }
}