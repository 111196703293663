import React from "react"
import axios from "axios"
import {toast} from 'react-toastify';
import Loader from "./Loader"
import moment from "moment"
import {Navigate} from "react-router-dom"
import {observer} from "mobx-react"
import ControleStore from "./ControleStore"
import SmartTextArea from "../../Shared/UiKit/SmartTextArea";
import Preferences from "../../Shared/Preferences/Preferences";
import {defaultShortcuts} from "../../Shared/Preferences/hooks/useShortcuts";


function toggleArrayItem(arr, item) {
    if (arr.includes(item)) {
        return (arr.filter(i => i !== item))
    } else {
        return ([...arr, item])
    }

}

@observer
class PathologieAndComment extends React.Component {
    constructor(props) {
        super(props);
        this.handleWriting = this.handleWriting.bind(this)
        this.sendComment = this.sendComment.bind(this)
        this.handleGravite = this.handleGravite.bind(this)
        this.isRedirect = this.isRedirect.bind(this)
        this.vider = this.vider.bind(this)
        this.handleMultiple = this.handleMultiple.bind(this)
        this.handleWritingConclusion = this.handleWritingConclusion.bind(this)
        this.handlePatho = this.handlePatho.bind(this)
        this.handleExam = this.handleExam.bind(this)
        this.conclude = this.conclude.bind(this)

        this.state = {
            text: '',
            loading: false,
            done: false,
            gravite: "",
            possibilites: ["rien", "bénin", "moyen", "grave", "présence de rpd", "pas de rpd", 'redirection', "mail", "mail-sms"],
            fo: false,
            ang: false,
            oct: false,
            topocornee: false,
            redirect: false,
            champVisuel: false,
            risques: props.textealertes,
            risque_table: [],//monkey patch
            risque: "",
            deseases: [],
            message_sms: "",
            needed_exams: [],
            patho: '',
            exam: '',
            conclusion_text: '',
            shortcuts: []
        };
        let csrfToken = document.querySelector("meta[name='csrf-token']").content
        axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
        this.getTextualShortcuts()
    }

    getTextualShortcuts = () => {
        Preferences.get('textual_shortcuts').then(shortcuts => this.setState(
            {shortcuts: [...JSON.parse(shortcuts), ...defaultShortcuts] ?? []
            }))
    }

    loadData() {
        if (ControleStore.app_id === "") {
            this.setState({redirect: true, done: true})
        } else {
            axios.post("/api/admin/controle/synthese", {id: ControleStore.app_id}).then((data) => {
                data = data.data
                if (data.status === "all-done") {
                    window.location.replace("/admin/controle_rdv")
                }
                this.setState({
                    id: data.id,
                    patient: data.patient,
                    images: data.images,
                    age: moment().diff(data.patient.birthday, "years"),
                    commentaire: data.commentaire,
                    orthoptiste: data.orthoptiste,
                    loading: false,
                })
            })
        }
    }

    vider(e) {
        e.preventDefault()
        this.setState({text: ""})
    }

    handlePatho(risk) {
        if (this.state.patho === '') {
            this.setState({
                patho: "À la lecture des examens réalisés, il apparaît une suspicion de : " + risk.titre,
                risque_table: toggleArrayItem(this.state.risque_table, risk)
            }, () => {
                this.conclude()
            })
        } else {
            if (this.state.patho.includes(risk.titre)) {
                let tmp = this.state.patho
                const regPlusBefore = new RegExp(":\\\s*\\+")
                const regTitle = new RegExp(`\\\s*\\+?\\s*${risk.titre}\\\s*`)
                tmp = tmp.replace(regTitle, '').replace(regPlusBefore, ":")
                if (toggleArrayItem(this.state.risque_table, risk).length === 0) {
                    tmp = ""
                }
                this.setState({
                    patho: tmp,
                    risque_table: toggleArrayItem(this.state.risque_table, risk)
                }, () => {
                    this.conclude()
                })
            } else {
                this.setState((prevState) => ({
                    patho: prevState.patho + " + " + risk.titre,
                    risque_table: toggleArrayItem(prevState.risque_table, risk)
                }), () => {
                    this.conclude()
                });
            }
        }
    }

    handleExam(exam) {
        if (this.state.exam === '') {
            this.setState({
                exam: "Nous recommandons que ce patient soit reconvoqué afin d'effectuer le ou les examens suivant(s) : " + exam.title,
                needed_exams: toggleArrayItem(this.state.needed_exams, exam.title)
            }, () => {
                this.conclude()
            })
        } else {
            if (this.state.exam.includes(exam.title)) {
                let tmp = this.state.exam
                const regPlusBefore = new RegExp(":\\\s*\\+")
                const regTitle = new RegExp(`\\\s*\\+?\\s*${exam.title}\\\s*`)
                tmp = tmp.replace(regTitle, '').replace(regPlusBefore, ":")
                if (toggleArrayItem(this.state.needed_exams, exam.title).length === 0) {
                    tmp = ""
                }
                this.setState({
                    exam: tmp,
                    needed_exams: toggleArrayItem(this.state.needed_exams, exam.title)
                }, () => {
                    this.conclude()
                })
            } else {
                this.setState((prevState) => ({
                    exam: prevState.exam + " + " + exam.title,
                    needed_exams: toggleArrayItem(this.state.needed_exams, exam.title)
                }), () => {
                    this.conclude()
                });
            }
        }
    }

    conclude() {
        this.setState({
            conclusion_text: this.state.patho + (this.state.patho ? "\n\n" : "") + this.state.exam
        })
    }

    handleWriting(e) {
        this.setState({text: e.target.value})
    }

    handleWritingConclusion(e) {
        this.setState({
            conclusion_text: e.target.value
        }, () => {
            if (this.state.conclusion_text === "" || this.state.conclusion_text === "\n") {
                this.setState({
                    risque_table: [], needed_exams: [], patho: "", exam: ""
                })
            }
        })
    }

    handleGravite(e) {
        e.preventDefault();
        let gravite = e.target.attributes.value.value
        this.setState({
            gravite: gravite
        })
        axios.post("/api/admin/controle/message_sms", {app_id: ControleStore.app_id, gravite: gravite}).then((data) => {

            this.setState({
                message_sms: data.data.message
            })
        })
    }

    handleMultiple(e) {
        this.setState({
            deseases: e.target.value,
            risque: e.target.value.join(", ")
        })
    }


    sendComment(e) {
        e.preventDefault()
        let s = this.state
        axios.post("/api/admin/controle/alerter", {
            id: ControleStore.app_id,
            risque: s.risque_table.map(risk => risk.message).join(", "),
            needed_exams: s.needed_exams,
            text: this.state.text,
            gravite: this.state.gravite,
            conclusion_text: this.state.conclusion_text
        }).then((data) => {
            toast.success("Alerte enregistrée", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            if (!isNaN(parseInt(this.props.appointmentId))) {
                window.location.href = this.props.fallback || `/patients/${this.state.patient.id}`
            } else this.setState({done: true, redirect: true})
        })
    }


    isRedirect() {
        if (this.state.redirect) {
            return (
                <Navigate to="/"/>
            )

        } else {
            return (<div></div>)
        }
    }

    componentDidMount() {
        this.setState({text: ""})
        this.loadData()

    }

    back() {
        window.history.back();
    }


    render() {
        const checkPatho = (this.state.message_sms !== "" && !!this.state.conclusion_text) || this.state.gravite === "rien"
        if (this.state.done === false) {
            return (
                <div id="full-height">
                    <div style={{display: "flex", width: "100%", gap: 20, padding: 20}}>
                        <div style={{display: "flex", width: "100%", gap: 20}}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                width: "30%",
                                gap: 20
                            }}>
                                <div style={{display: "flex", width: "100%", gap: 10}}>
                                    {/*NIVEAU D'ALERTE*/}
                                    <div style={{width: "100%"}}>
                                        <div>
                                            <h3 className="center"><b> Niveau d'alerte </b>
                                            </h3>
                                            <div className="collection">
                                                {this.state.possibilites.map((poss, index) => (
                                                    <a key={index}
                                                       className={"collection-item " + (this.state.gravite === poss ? "active" : "")}
                                                       onClick={this.handleGravite} value={poss}>{poss}</a>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {/*MESSAGE POUR LE PATIENT*/}
                                <div style={{width: "100%"}}>
                                    <div>
                                        <h2 style={{marginTop: 0, marginBottom: "10px"}}>
                                            <i className="tiny material-icons" style={{marginRight: "4px"}}>input</i>
                                            <b>Message pour le patient</b>
                                        </h2>
                                        <SmartTextArea
                                            value={this.state.message_sms}
                                            onChange={(e) => this.setState({message_sms: e.target.value})}
                                            shortcuts={this.state.shortcuts}
                                            className="row card-panel blue lighten-5" style={{
                                            height: "28vh",
                                            border: "none",
                                            outline: "none",
                                            borderTop: "thick solid #32a1ce",
                                            marginTop: "0px",
                                            borderRadius: "0px 0px 30px 30px",
                                            resize: "none",
                                            paddingBlock: 20
                                        }}

                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "100%",
                                width: "70%",
                                gap: 20
                            }}>
                                <div style={{display: "flex", width: "100%", gap: 20}}>
                                    {/*PATHO SOUPCONNEE*/}
                                    <div style={{width: "50%"}}>
                                        <div className="input-field col s6 l6" style={{marginTop: "0px"}}>
                                            <h2 className="center"><b> Pathologie soupçonnée</b></h2>
                                            {this.state.risques.map((risk) => (
                                                <p key={risk.titre} style={{marginBottom: "0.5em", marginTop: "0.5em"}}>
                                                    <label>
                                                        <input type="checkbox" value={risk}
                                                               checked={this.state.risque_table.includes(risk) && this.state.conclusion_text !== ""}
                                                               onChange={() => this.handlePatho(risk)}/>
                                                        <span>{risk.titre}</span>
                                                    </label>
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                    {/*EXAMEN CONSEILLE*/}
                                    <div style={{width: "50%"}}>
                                        <div className="input-field col s6 l6" style={{marginTop: "0px"}}>
                                            <h2 className="center"><b>Examens à réaliser</b></h2>
                                            {ControleStore.possible_examens.map((exam) => (
                                                <p key={exam.id + "_" + exam.title}>
                                                    <label>
                                                        <input type="checkbox" value={exam.title}
                                                               checked={this.state.needed_exams.includes(exam.title) && this.state.conclusion_text !== ""}
                                                               onChange={() => this.handleExam(exam)}
                                                        />
                                                        <span>{exam.title}</span>
                                                    </label>
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*BOUTONS*/}
                        <div style={{width: "60%"}}>
                            <div className="valign-wrapper" style={{gap: 20}}>
                                <h6 style={{width: "100%", margin: 0, paddingInline: 60}}
                                    className={checkPatho ? "hide" : ""}>
                                    <i>Il vous faut obligatoirement un message de compte rendu, et un niveau d'alerte.
                                    </i>
                                </h6>
                                <div style={{display: "flex", flexDirection: "column", marginLeft: "auto", width: 300}}>
                                    <a onClick={this.back} style={{borderRadius: "10px", width: "100%"}}
                                       className="btn btn-large truncate red z-depth-0">
                                        <i className="material-icons right hide-on-med-and-down">clear</i>Retour</a>
                                    <p><a onClick={this.sendComment} style={{borderRadius: "10px", width: "100%"}}
                                          className={!checkPatho ? "disabled truncate btn btn-large green z-depth-0" : "btn truncate btn-large green z-depth-0"}><i
                                        className="material-icons right hide-on-med-and-down">done</i>Envoyer</a></p>
                                </div>

                            </div>
                            {/*CONCLUSION*/}
                            <div style={{width: "100%"}}>
                                <h2 style={{marginTop: 0, marginBottom: "10px"}}>
                                    <i className="tiny material-icons"
                                       style={{marginRight: "4px", marginLeft: "10px"}}>input</i>
                                    <b>Conclusion</b>
                                </h2>
                                <label style={{marginLeft: "10px"}}>
                                    Ce message sera celui transmis à la conclusion de votre compte rendu.
                                </label>
                                <br/>
                                <label style={{marginLeft: "10px"}}>
                                    Les "+" seront remplacés par une liste à puce dans le rendu final.
                                </label>
                                <SmartTextArea
                                    className="row card-panel blue lighten-5"
                                    value={this.state.conclusion_text}
                                    onChange={this.handleWritingConclusion}
                                    shortcuts={this.state.shortcuts}
                                    style={{
                                        height: "35vh",
                                        width: "100%",
                                        border: "none",
                                        outline: "none",
                                        borderTop: "thick solid #32a1ce",
                                        marginLeft: "10px",
                                        borderRadius: "0px 0px 30px 30px",
                                        resize: "none",
                                        paddingBlock: 20
                                    }}
                                />
                            </div>
                            {/*MESSAGE ORTHO*/}
                            <div style={{width: "100%"}}>
                                <h2 style={{marginBottom: "10px", marginTop: "20px"}}>
                                    <i className="tiny material-icons"
                                       style={{marginRight: "4px", marginLeft: "10px"}}>input</i>
                                    <b>Message pour l'orthoptiste</b>
                                </h2>
                                <SmartTextArea
                                    className="row card-panel blue lighten-5"
                                    onChange={this.handleWriting} value={this.state.text}
                                    shortcuts={this.state.shortcuts}
                                    style={{
                                        height: "27vh",
                                        width: "100%",
                                        border: "none",
                                        outline: "none",
                                        borderTop: "thick solid #32a1ce",
                                        marginLeft: "10px",
                                        borderRadius: "0px 0px 30px 30px",
                                        resize: "none",
                                        paddingBlock: 20
                                    }}
                                />
                                {/*</textarea>*/}
                                <label htmlFor="textarea1"></label>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="valign-wrapper page">
                        <div className="center-loader">
                            <Loader/>
                            {this.isRedirect()}

                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }
}

// NIVEAU ALERTE


// MESSAGE POUR LE PATIENT


// PATHO SOUPCONNEES


// EXAMEN A REALISER


// BOUTON


// MESSAGE ORTHOPTISTE

export default PathologieAndComment
