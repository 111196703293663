import React, {useEffect, useState} from "react";
import {RequestState} from "../Shared/StateHelper";
import Loader from "../Shared/Loader";
import Error from "../Shared/Error";
import axios from "axios";
import AddPrescriptorButton from "./AddPrescriptorButton";
import Helper from "../Shared/Helper";
import {Act} from "../Shared/Enums/Enums";
import CVStore from "../CarteVitale/CVStore";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

function SelectPrescriptor({selectedPrescriptor, onSelect}) {
    const [state, setState] = useState(RequestState.LOADING);
    const [prescriptors, setPrescriptors] = useState([]);

    useEffect(() => {
        getPrescriptors();
    }, [])

    const getPrescriptors = () => {
        axios.get("/prescripteurs").then(res => {
            const prescriptors = res.data.prescriptors.filter(prescriptor => prescriptor.firstname !== "" && prescriptor.lastname !== "");
            setPrescriptors(prescriptors);
            if (MesureStore.selected_act.titre === Act.RNM) {
                const analyst_prescriptor_id = res.data.analyst_prescriptor_id;
                CVStore.selectedPrescriptor = prescriptors.find(prescriptor => prescriptor.id === analyst_prescriptor_id)
            }
            setState(RequestState.SUCCESS);
        }).catch(err => {
            setState(RequestState.ERROR);
        })
    }

    if (RequestState.LOADING === state) return <div style={{margin: 20}}><Loader text="" size="small"/></div>
    if (RequestState.ERROR === state) return <div style={{margin: 20}}>
        <Error errorText="Impossible de charger les données" size="small"/>k
    </div>

    return (
        <div style={{display: "flex", flexDirection: "column", gap: 20}}>
            <h5 style={{margin: 0}}>Sélectionner un prescripteur</h5>
            <Autocomplete
                className="ui-autocomplete"
                value={CVStore.selectedPrescriptor}
                options={prescriptors}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => Helper.formatName(option.firstname, option.lastname)}
                filterSelectedOptions
                onChange={(_, prescriptor) => CVStore.selectedPrescriptor = prescriptor}
                sx={{width: "100%"}}
                renderInput={(params) => <TextField {...params}/>}
            />
            <AddPrescriptorButton onSave={(prescriptor) => {
                onSelect(prescriptor)
                getPrescriptors()
            }}/>
        </div>
    )
}

export default SelectPrescriptor;