import React from "react"
import {observer} from "mobx-react";
import {AppointmentStatus} from "../../Shared/Enums/Enums";
import MedicalEventStore from "../MedicalEventStore";

const _ = require('lodash')
const ListPatients = observer(() => {

    const renderStatus = (patient) => {
        switch (patient.status_controle) {
            case AppointmentStatus.NOT_SUBMITTED.code:
                return <i className="material-icons grey-text valign-wrapper" title="Examen de test">
                    do_not_disturb_on
                </i>
            case AppointmentStatus.DEFAULT.code:
                return <i className="material-icons grey-text valign-wrapper" title="Examen à réaliser">
                    calendar_month
                </i>
            case AppointmentStatus.TO_CONTROL.code:
                return <i className="material-icons orange-text text-darken-2 valign-wrapper" title="En attente de relecture">
                    pending
                </i>
            case AppointmentStatus.OK.code:
                return <i className="material-icons green-text text-darken-2 valign-wrapper" title="Dossier validé">
                    check
                </i>
            case AppointmentStatus.ALERTED.code:
                return <i className={`material-icons ${patient.override_lock ? "blue-text" : "red-text"} text-darken-2`}
                          title={patient.override_lock ? "Dossier alerté et débloqué" : "Dossier en alerte"}>
                    warning
                </i>
        }
    }

    const renderPortalKey = (patient) => {
        if (!patient.portal_key) return null
        return <a className="btn-flat btn-small blue lighten-5 truncate" style={{width: '100%'}}
                  onClick={() => navigator.clipboard.writeText(patient.portal_key)}
                  title="Clé de connection au portail patient">
            {patient.portal_key}
        </a>
    }

    const renderPatient = (patient) => {
        return <tr className="highlighted">
            <td style={{height: "100%"}}>{renderStatus(patient)}</td>
            <td>{renderPortalKey(patient)}</td>
            <td>{patient.act_name ?? "Non renseigné"}</td>
            <td>{Helper.formatName(patient.firstname, patient.lastname)}</td>
            <td>Né(e)
                le {patient.birthday} ({Helper.dateToAge(patient.birthday)} ans)
            </td>
            <td>{Helper.formatSsn(patient.ssn)}</td>
            <td>{patient.email}</td>
            <td>{patient.telephone}</td>
            <td>
                {Helper.formatPatientCodes(patient.regime_code, patient.fund_code, patient.centre_code)}
            </td>
            <td>{patient.health_insurance}</td>
        </tr>
    }

    const renderPatients = () => {
        if (_.isEmpty(MedicalEventStore.patients)) {
            return <div className="center-align" style={{paddingTop: 50}}>
                <h5>Aucun patient n'est affecté à l'évènement</h5>
            </div>
        }
        return <table className="temeoo-table">
            <thead>
            <tr>
                <th>Status</th>
                <th>Cle espace patient</th>
                <th>Acte</th>
                <th>Nom</th>
                <th>Date de naissance</th>
                <th>Numéro de sécurité sociale</th>
                <th>Email</th>
                <th>Téléphone</th>
                <th>Code organisme</th>
                <th>Mutuelle</th>
            </tr>
            </thead>
            <tbody>
            {MedicalEventStore.patients.map((patient) => renderPatient(patient))}
            </tbody>
        </table>
    }

    return <div style={{display: "flex", flexDirection: "column", gap: 20, padding: 40}}>
        {renderPatients()}
    </div>
})

export default ListPatients