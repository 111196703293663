import React, {useEffect} from 'react';
import StripeSepaFrom from "./StripeSepaFrom";
import axios from "axios";
import {RequestState} from "../../../../Shared/StateHelper";
import Loader from "../../../../Shared/Loader";
import Error from "../../../../Shared/Error";
import {toast} from "react-toastify";

const BillingPanel = ({url_prefix, stripePublic}) => {
    let csrfToken = document.querySelector("meta[name='csrf-token']").content
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

    const [clientSecret, setClientSecret] = React.useState(null);
    const [user, setUser] = React.useState({});
    const [alreadySetup, setAlreadySetup] = React.useState(false);
    const [requestStatus, setRequestStatus] = React.useState(RequestState.IDLE);
    useEffect(() => {
        getStripeCustomer();
    }, []);

    const getStripeCustomer = () => {
        setRequestStatus(RequestState.LOADING)
        axios.get(`${url_prefix}/billing/setup`)
            .then(response => {
                setClientSecret(response.data.client_secret)
                setAlreadySetup(response.data.already_setup)
                setUser({
                    fullname: response.data.fullname,
                    email: response.data.email
                })
                setRequestStatus(RequestState.SUCCESS)
            })
            .catch(error => {
                setRequestStatus(RequestState.ERROR)
            });
    }

    const createStripeCustomer = (data) => {
        axios.post(`${url_prefix}/billing/create_customer`, data)
            .then(response => {
                setAlreadySetup(true)
                toast.success('Votre compte en banque a été enregistré avec succès')
            })
            .catch(error => {
                toast.error('Une erreur est survenue lors de l\'enregistrement de votre compte en banque')
            });
    }
    const renderAlreadySetup = () => {
        if(!alreadySetup) return null;
        return <div className="center" style={{padding: 20}}>
            <h2 style={{margin: 0, fontWeight: "bolder"}}>Vous avez déjà configuré votre facturation</h2>
            <h6 style={{fontWeight: "bold"}}>Vous pouvez entrer un nouvel IBAN si vous souhaitez changer de compte en banque.</h6>
        </div>
    }

    if (requestStatus === RequestState.LOADING) return <div id="full-height" style={{padding: 200}}>
        <Loader/>
    </div>
    if (requestStatus === RequestState.ERROR) return <div id="full-height" style={{padding: 200}}>
        <Error errorText="Impossible de charger la page"/>
    </div>


    return <div id="full-height" style={{padding: 20, overflowY: "scroll"}}>
        <h1 style={{marginBlock: 20}}>
            Facturation
            <span className={`badge ${alreadySetup ? "green" : "red"} darken-2 white-text`} style={{borderRadius: 5}}>
                {alreadySetup ? "Déjà configuré" : "Non configuré"}
            </span>
        </h1>
        <div className="divider"/>
        {renderAlreadySetup()}
        <div style={{marginBlock: 20}}>
            <StripeSepaFrom clientSecret={clientSecret} onValidation={createStripeCustomer} user={user} stripePublic={stripePublic}/>
        </div>
    </div>
}

export default BillingPanel;