import React from 'react';
import Barcode from "react-barcode";
import Helper from "../../Shared/Helper";
import Logo from "../../Shared/UiKit/Logo";

const DocumentHeader = ({orthoptist, ophtalmologist, patient, appointment}) => {
    const getPrefix = () => (patient.gender === 0) ? "M." : (patient.gender === 1) ? "Mme." : "";

    return <>
        <div style={{display: "flex", flexDirection: "row"}}>
            <div style={{flexDirection: "column", display: "flex", justifyContent: "center", width: 400}}>
                <Logo text style={{maxWidth: 200}}/>
                <h5 style={{marginBottom: 0}}>Dr {ophtalmologist.nom} - OPHTALMOLOGISTE</h5>
                <h5 style={{marginBlock: 5}}>{ophtalmologist.address ? ophtalmologist.address : ""}</h5>
                <h5 style={{marginBlock: 0}}>{ophtalmologist.postal_code} {ophtalmologist.city}</h5>
                <h5 style={{marginBlock: 5}}>{ophtalmologist.office_name ? "Cabinet : " + ophtalmologist.office_name : ""}</h5>
                <h5 style={{marginBlock: 0}}>{orthoptist.phone_number ? "Tél : " + orthoptist.phone_number : ""}</h5>
            </div>
            <div style={{flex: "1", display: "flex", flexDirection: "row", paddingRight: 20}}>
                <h5 style={{marginInline: 50}}>N°AM :
                    <Barcode value={ophtalmologist.adeli} width={1} height={50} fontSize={10}/>
                </h5>
                <h5>N°RPPS :<Barcode value={ophtalmologist.rps} width={1} height={50} fontSize={10}/></h5>
            </div>
        </div>
        <div style={{textAlign: "right", paddingRight: 20}}>
            <h5 style={{marginBottom: 15}}>Le {Helper.formatDate(appointment.date)}</h5>
            {/*Patient*/}
            <h5 style={{marginBlock: 5}}><b>{getPrefix()} {Helper.formatName(patient.firstname, patient.lastname)}</b>
            </h5>
            <h5 style={{marginBlock: 5}}>Né(e) le {Helper.formatDate(patient.birthday)}</h5>
            <h5 style={{marginBlock: 5}}>{patient.email}</h5>
            <h5 style={{marginBlock: 5}}>{patient.tel}</h5>
        </div>
    </>
}

export default DocumentHeader;