import test1 from '../fr.json';
import LoginPanel from './Login/LoginPanel.json';
import AskFor2FA from "./Login/AskFor2FA.json";
import NavBar from "./NavBar/NavBar.json";
import Common from './common.json';
import UserDropdown from "./NavBar/UserDropdown.json";
import ExamHistory from "./Patient/ExamHistory.json";
import PatientHistory from "./Patient/PatientHistory.json";
import PatientInfo from "./Patient/PatientInfo.json";
import Account from "./Orthoptist/Account.json";
import Users from "./Console/Users.json";
import Console from "./Console/Console.json";
import Options from "./Options/Options.json";
import GeneralStatistics from "./Statistics/Ophtalmologist/GeneralStatistics.json";
import Dashboard from "./Statistics/Orthoptist/Dashboard.json";
import PrescriptionStore from "./Appointments/Mesures/PrescriptionStore.json";
import Prescriptions from "./Appointments/Mesures/mesures_pages/Prescriptions.json";
import BillingPreview from "./Orthoptist/BillingPreview.json";
import Phone from "./Orthoptist/Phone.json";
import ShowAgenda from "./Agenda/showAgenda.json";
import ActiveTabs from "./Appointments/Mesures/measure_components/ActiveTabs.json";
import FondYeux from "./Appointments/Mesures/FondYeux.json";
import ImageSelectionModal from "./Shared/ImageSelectionModal.json";
import MedicalQuestions from "./Appointments/Mesures/measure_components/Questions/MedicalQuestions.json";
import BypassConstraintsButton from "./Orthoptist/BypassConstraintsButton.json";
import AppointmentRoutes from "./Appointments/Mesures/AppointmentRoutes.json";
import Pathologie from "./App_pathologie/Pathologie.json";
import Reglement from "./Rdv_reglement/Reglement.json";
import Statistic from "./Statistics/Orthoptist/Statistics.json";
import PatientPortal from "./Patient Portal/PatientPortal.json";
import Messages from "./Ophtalmologist/Messages.json";
import Tokens from "./Shared/Tokens.json";
import OpticianDashboard from "./Optician/Dashboard.json";
import Search from "./Shared/Search.json";
import AlertFollowUp from "./Orthoptist/AlertFollowUp.json";
import Header from "./Header/Header.json";
import AppointmentValidator from "./Orthoptist/AppointmentValidator.json";
import Conversation from "./Messenger/Conversation.json";
import MainPage from "./AlertTexts/MainPage.json";
import SecretariatMailPage from "./Secretariat/Mailer/SecretariatMailPage.json";
import ListTeletransmission from "./Secretariat/Teletransmission/ListTeletransmission.json";
import GeneralSummaryPanel from "./Admin/Controle/GeneralSummaryPanel.json";
import ControleComponent from "./Admin/Controle/Controle.json";
import Alerts from "./Alerts/Alerts.json";
import Monitoring from "./Ophtalmologist/Monitoring.json";
import BillingPreviewOphtalmo from "./Ophtalmologist/BillingPreview.json";
import HistoryOphtalmo from "./Ophtalmologist/History.json";
import AlertDetails from "./Ophtalmologist/AlertDetails.json";
import ViewerMetadata from "./Viewer/ViewerMetadata.json";
import SelectTemplate from "./Report/SelectTemplate.json";
import PossibleExamens from "./Ophtalmologist/PossibleExamens.json";
import MedicalEvents from "./Console/MedicalEvents.json";
import PaymentTracker from "./Console/PaymentTracker.json";

export default {
    ...test1,
    ...LoginPanel,
    ...Common,
    ...AskFor2FA,
    ...NavBar,
    ...UserDropdown,
    ...ExamHistory,
    ...PatientHistory,
    ...PatientInfo,
    ...Account,
    ...Users,
    ...Console,
    ...Options,
    ...GeneralStatistics,
    ...Dashboard,
    ...BillingPreview,
    ...Phone,
    ...ShowAgenda,
    ...PrescriptionStore,
    ...Prescriptions,
    ...ActiveTabs,
    ...FondYeux,
    ...ImageSelectionModal,
    ...MedicalQuestions,
    ...BypassConstraintsButton,
    ...AppointmentRoutes,
    ...Pathologie,
    ...Reglement,
    ...ImageSelectionModal,
    ...Statistic,
    ...PatientPortal,
    ...Messages,
    ...Tokens,
    ...OpticianDashboard,
    ...AlertFollowUp,
    ...Header,
    ...Search,
    ...AppointmentValidator,
    ...Conversation,
    ...MainPage,
    ...SecretariatMailPage,
    ...ListTeletransmission,
    ...GeneralSummaryPanel,
    ...ControleComponent,
    ...Alerts,
    ...Monitoring,
    ...BillingPreviewOphtalmo,
    ...HistoryOphtalmo,
    ...AlertDetails,
    ...ViewerMetadata,
    ...SelectTemplate,
    ...PossibleExamens,
    ...MedicalEvents,
    ...PaymentTracker
}