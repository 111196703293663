import React from "react";
import {observer} from "mobx-react";
import {JOB} from "../../Shared/Helper";
import MedicalReport from "./Report/MedicalReport";
import DrugsReport from "./Report/DrugsReport";
import {ActNature} from "../../Shared/Enums/Enums";
import BBCode from "../../Shared/BBCode";
import {t} from "../../I18n/i18n"

const telemedical_questions_json = require("../../Appointments/Mesures/mesure_components/Questions/telemedical_questions.json")
const nurse_question_json = require("../../Appointments/Mesures/mesure_components/Questions/nurse_questions.json")

@observer
class GeneralSummaryPanel extends React.Component {
    constructor(props) {
        super(props);
        this.isAlert = this.isAlert.bind(this)
    }

    isAlert() {
        let alert = false
        let messages = []
        this.props.mesures.map((mesure) => {
            if (mesure.od_tension > 21 || mesure.og_tension > 21) {
                alert = true
                messages.push(t("GENERAL_SUMMARY_PANEL.TENSION_TOO_HIGH"))
            }
            if (mesure.typedemesure_id == 12) {
                if (!([null, "", "0.9", "1", "1.0", "12", "16","1.2","1.20","1.25","1.6"].includes(mesure.og_av_loin)) || !([null, "", "0.9", "1", "1.0", "12", "16","1.2","1.20","1.25","1.6"].includes(mesure.od_av_loin))) {
                    alert = true
                    messages.push(t("GENERAL_SUMMARY_PANEL.LOW_VISUAL_ACUITY"))
                }
            }
        })
        if (alert) {
            messages = Array.from(new Set(messages))
            return (
                <div className="red white-text floating-alert">
                    <h4 className="center">{t("GENERAL_SUMMARY_PANEL.PROBLEM_DETECTED")}</h4>
                    <ul>
                        {messages.map((message, index) => (
                            <li key={index}>{message}</li>
                        ))}
                    </ul>
                </div>
            )
        } else {
            return (<div></div>)
        }
    }

    renderReport = () => {
        if(!this.props.medical_report) return null
        if (this.props.act.nature === ActNature.TELEMEDICAL.name) return <MedicalReport report={this.props.medical_report} json={telemedical_questions_json} />
        if (this.props.act.nature === ActNature.NURSE.name) return <MedicalReport report={this.props.medical_report} json={nurse_question_json} />
        return null
    }

    // birthay since {year: 24, month: 1, day: 1}
    computeBirthday = () => {
        const birthday = this.props.summary.birthday
        if (!birthday) return null
        const birth = new Date(birthday)
        const now = new Date()
        let age = now.getFullYear() - birth.getFullYear()
        let month = now.getMonth() - birth.getMonth()
        if (month < 0 || (month === 0 && now.getDate() < birth.getDate())) {
            age--
            month += 12
        }
        return {year: age, month: month}
    }

    render() {
        const alert = this.props.summary.priority === 1;
        const mystyle = {
            borderRadius: "0px 0px 30px 30px",
            borderTop: "thick solid #32a1ce",
        };
        if (alert == true) {
            mystyle.borderTop = "thick solid crimson"
        }
        return (
            <div>
                {this.isAlert()}
                <div className="row">
                    <div className="col s3 center-align" style={{fontSize: "0.8em"}}>
                        <h2><b>{t("PATIENT")}</b></h2>
                    </div>
                    <div className="col s5 center-align" style={{fontSize: "0.8em"}}>
                        <h2><b>{t("COMMENT")} {JOB.toLowerCase()}</b></h2>
                    </div>
                    <div className="col s4 center-align" style={{fontSize: "0.8em"}}>
                        <h2><b>{t("GENERAL_SUMMARY_PANEL.CLINICAL_DATA")}</b></h2>
                    </div>
                </div>
                <div className={"row " + (alert ? "card-panel red lighten-4" : "card-panel blue lighten-5")}
                     style={mystyle}>
                    <div className="col s3">
                        <ul style={{paddingLeft: 5}}>
                            <li>{this.props.summary.name}</li>
                            {this.computeBirthday() ? <li>{this.computeBirthday().year} {t("YEARS_OLD")} {this.computeBirthday().month} {t("MONTHS")}</li> : <div/>}
                            <li>{this.props.summary.phone}</li>
                            {this.props.summary.history ? <li style={{fontWeight: "20px"}}><b>{t("BACKGROUND")} :</b>
                                {this.props.summary.history.split('\n').filter(i => i).map((item, i, arr) => <h6 key={"t"+i} style={{margin: 4}}>{`${arr.length > 1 ? '-' : ""} ${item}`}</h6>)}
                            </li> : <div/>}
                            <li style={{fontWeight: "20px"}}>
                                <b>{t("GENERAL_SUMMARY_PANEL.REVIEW_BY")} :</b><br/> {this.props.summary.orthoptist} {this.props.summary.city ? `(${this.props.summary.city})` : ""}
                            </li>
                            <li><b>{t("GENERAL_SUMMARY_PANEL.LAST_MODIFICATION")} : </b><br/>{this.props.summary.date}
                            </li>
                            <li><b>{t("ACTS")} : </b><br/>{this.props.summary.acts.join(", ")} </li>

                        </ul>
                    </div>
                    <div className="col s5" style={{whiteSpace: "pre-wrap"}}>
                        {
                            this.props.summary.bypass_reason ? (
                                <div>
                                    <h5 className="bolder red-text">{t("GENERAL_SUMMARY_PANEL.DEACTIVATED_CONSTRAINTS")}</h5>
                                    <p className="bold red-text">{this.props.summary.bypass_reason}</p>
                                </div>
                            ) : (
                                <div></div>
                            )
                        }
                        {this.props.summary.stereoscopy ?( <>
                                <p style={{marginBottom: 0}}><b>{t("GENERAL_SUMMARY_PANEL.STEREOSCOPY")} :</b></p>
                                <p style={{marginTop: 0}}>{this.props.summary.stereoscopy}</p>
                            </>)
                            : <div/>
                        }

                        <BBCode text={this.props.summary.comment ?? ""}/>
                        <BBCode text={this.props.summary.orthoptic_text ?? ""}/>
                        {this.props.summary.comment_for_ophtalmologist ? <BBCode
                            text={`${t("COMMENT")} : ` + this.props.summary.comment_for_ophtalmologist ?? ""}/> : null}
                        {this.props.summary.pathologies.length > 0 ? (
                            <div>
                                <h2 className="center red-text">{t("PATHOLOGY")} !</h2>
                                {this.props.summary.pathologies.map((pathologie,index) => (
                                    <p key={"pat"+index}
                                       className="center red-text">{pathologie.pathologie}</p>
                                ))}
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                    <div className="col s4">
                        {this.renderReport()}

                        {!this.props.summary.questions67.amy6_diabete ? <div/> : (
                            <ul>
                                <li>
                                    <b>{t("GENERAL_SUMMARY_PANEL.DIABETES")}</b> : {this.props.summary.questions67.amy6_diabete} |
                                    {t("GENERAL_SUMMARY_PANEL.DISCOVER")} {this.props.summary.questions67.annee_decouverte_diabete}
                                </li>
                                <li><b>hb1ac</b> : {this.props.summary.questions67.hb1ac}</li>
                                <li>
                                    <b>{t("GENERAL_SUMMARY_PANEL.OPHTHALMIC_FOLLOW_UP")}</b> : {this.props.summary.questions67.suivi_ophtalmique}
                                </li>
                                <li>
                                    <b>{t("GENERAL_SUMMARY_PANEL.LAST_OPHTA_EXAM")}</b> : {this.props.summary.questions67.ophtalmologiste_dernier_examen}
                                </li>
                                <li><b>hta</b> : {this.props.summary.questions67.hta}</li>
                            </ul>
                        )}
                        <DrugsReport drugs={this.props.patient.drugs} />
                    </div>
            </div>
    </div>
    )
    }
}

export default GeneralSummaryPanel
