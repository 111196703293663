import React from "react";

const DocumentConclusion = ({conclusion, appointment, message}) => {
    const renderMessage = () => {
        if (conclusion.conclusion_text) {
            return conclusion.conclusion_text
        } else if (message[0]?.message) {
            return message[0]?.message
        } else if (appointment.ophtalmologist_comment) {
            return appointment.ophtalmologist_comment
        } else {
            return "À la lecture du dossier, l'examen est rassurant."
        }
    }

    return <>
        <h5 style={{marginBlock: 0}}>
            <b>Conclusion : </b>
        </h5>
        {appointment.status_controle >= 2 ? (
            <h5 style={{marginBottom: 20, marginTop: 3, whiteSpace: "pre-wrap"}}>
                {renderMessage()}
            </h5>
        ) : (
            <h5 style={{marginBottom: 20, marginTop: 3}}>
                L'examen n'a pas encore été contrôlé par un ophtalmologiste.
            </h5>
        )}
    </>
};

export default DocumentConclusion
