import React from "react";
import {NavLink, Route, Routes} from "react-router-dom";
import {Observer} from "mobx-react";
import {t} from "../../../I18n/i18n";
import ScopedElement from "../../../Shared/ScopedElement";
import {Role} from "../../../Shared/Enums/Scope";
import PersonalInformationPanel from "./PersonalInformationPanel";
import SecurityPanel from "./SecurityPanel";
import TwoFactorAuthPanel from "./TwoFactorAuthPanel";
import OfficePanel from "./OfficePanel";
import ReadingPanel from "./Reading/ReadingPanel";
import CustomizationPanel from "./CustomizationPanel";
import BillingPanel from "./Billing/BillingPanel";
import ManagementPanel from "./ManagementPanel";
import OrthoptistPanel from "../ArsTabs/OrthoptistPanel";
import OphtalmologistPanel from "../ArsTabs/OphtalmologistPanel";
import AddOphRef from "./AddOphRef";

// TODO class => func
export default class GeneralTabs {
    constructor(props, url_prefix) {
        this.props = props;
        this.url_prefix = url_prefix;

        const PERSONAL_INFO = {
            name: t("OPTIONS.PERSONAL_INFORMATIONS"),
            icon: "account_circle",
            path: "/personal_infos",
            scope: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST, Role.EXTERN, Role.OPTICIAN],
            element: <PersonalInformationPanel
                user_type={this.props.user_type}
                url_prefix={url_prefix}
                rpps={this.props.rpps}
                phone_number={this.props.phone_number}
                email={this.props.email}
                username={this.props.username}
                first_name={this.props.first_name}
                last_name={this.props.last_name}
                adeli={this.props.adeli}
                full_name={this.props.full_name}
                invoice_label={this.props.invoice_label}
                address={this.props.address}
                city={this.props.city}
                postal_code={this.props.postal_code}/>
        }
        const OFFICE = {
            name: t("OPTIONS.OFFICE.TITLE"),
            icon: "home",
            path: "/office",
            scope: [Role.ORTHOPTIST],
            element: <OfficePanel url_prefix={url_prefix} office={props.office}/>
        }
        const READING = {
            name: t("OPTIONS.READING.TITLE"),
            icon: "preview",
            path: "/reading",
            scope: [Role.OPHTALMOLOGIST],
            element: <ReadingPanel url_prefix={url_prefix}/>
        }
        const SECURITY = {
            name: t("OPTIONS.SECURITY"),
            icon: "security",
            path: "/security",
            scope: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST, Role.SECRETARY, Role.EXTERN, Role.OPTICIAN],
            element: <SecurityPanel url_prefix={url_prefix}/>
        }
        const BILLING = {
            name: t("OPTIONS.BILLING.TITLE"),
            icon: "credit_card",
            path: "/billing",
            scope: [Role.OPTICIAN],
            element: <BillingPanel url_prefix={url_prefix} stripePublic={props.stripePublic}/>
        }
        const TWOFA_HANDLING = {
            name: t("OPTIONS.2FA_HANDLING"),
            icon: "qr_code_scanner",
            path: "/two_factor_auth",
            scope: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST, Role.SECRETARY],
            element: <TwoFactorAuthPanel
                url_prefix={url_prefix}
                activated={this.props.two_fa_activated}
                code_generated={this.props.two_fa_code_generated}
            />
        }
        const PERSONAL_SHORTCUT = {
            name: t("OPTIONS.CUSTOMIZATION.TITLE"),
            icon: "account_box",
            path: "/customization",
            scope: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST],
            element: <CustomizationPanel/>
        }
        const ADD_OPH_REF = {
            name: t("OPTIONS.ADD_OPH_REF.TITLE"),
            icon: "group_add",
            path: "/add_oph_ref",
            scope: [Role.ORTHOPTIST],
            element: <AddOphRef/>
        }
        const MANAGE_EXAM_RISK = {
            name: t("POSSIBLE_EXAM.NAV_TITLE"),
            icon: "medical_information",
            path: "/management",
            scope: [Role.OPHTALMOLOGIST],
            element: <ManagementPanel/>
        }
        const ARS_DOCUMENTS = {
            name: "Documents ARS",
            icon: "edit_note",
            scope: [Role.OPHTALMOLOGIST, Role.ORTHOPTIST],
            path: "/ars_documents",
            element: this.props.user_type === Role.ORTHOPTIST ? <OrthoptistPanel data={this.props}/> : <OphtalmologistPanel data={this.props}/>
        }

        this.layout = {
            HOME: {
                name: undefined,
                entries: [
                    PERSONAL_INFO, OFFICE, READING, SECURITY, BILLING, TWOFA_HANDLING, ARS_DOCUMENTS
                ],
            },
            USERS: {
                name: "Gestion",
                entries: [
                    PERSONAL_SHORTCUT, MANAGE_EXAM_RISK, ADD_OPH_REF
                ],
                scope: [Role.ORTHOPTIST, Role.OPHTALMOLOGIST]
            }
        };
    }

    useComponents = () => [this.routes(), this.links()]

    routes = () => {
        return (
            <>
                {Object.values(this.layout).reduce((acc, category) => [...acc, ...category.entries], []).map((entry, index) => {
                    if (!entry.scope.includes(this.props.user_type)) return null;
                    return <Route key={`general_option_route_${index}`} path={entry.path} element={entry.element} />;
                })}
            </>
        );
    }

    links = () => {
        return (
            <Observer>
                {() => (
                    <div id="full-height" style={{display: "flex"}}>
                        <div style={{minWidth: "300px", boxShadow: "2px 0 0 0 #E4E4E4"}}>
                            <ul id="console_sidenav" className="sidenav sidenav-fixed white"
                                style={{marginTop: 0, height: "100%"}}>
                                {Object.values(this.layout).map((category, index) => {
                                    if (!category.name) {
                                        return [
                                            ...category.entries.map((entry, entryIndex) => (
                                                <ScopedElement scope={entry.scope}
                                                               key={`console_entry_${entry.name}_${entryIndex}`}>
                                                    <li className="navlink">
                                                        <NavLink to={entry.path} style={{paddingLeft: 5}}>
                                                            <i style={{margin: "0 12px 0 0"}}
                                                               className="material-icons">{entry.icon}</i>
                                                            {entry.name}
                                                        </NavLink>
                                                    </li>
                                                </ScopedElement>
                                            )),
                                            <li key={`divider_noname_${index}`}>
                                                <div className="divider" style={{margin: "10px 0"}}></div>
                                            </li>
                                        ];
                                    }
                                    return (
                                        <ScopedElement scope={category.scope} key={`console_category_${category.name}`}>
                                            <li>
                                                <ul className="collapsible collapsible-accordion"
                                                    id="collapsibleOptionPanel">
                                                    <li className="active">
                                                        <a className="collapsible-header grey-text text-darken-2">
                                                            {category.name}
                                                            <i className="material-icons">arrow_drop_down</i>
                                                        </a>
                                                        <ul className="collapsible-body">
                                                            {category.entries.map((entry, entryIndex) => (
                                                                <ScopedElement scope={entry.scope}
                                                                               key={`console_entry_${entry.name}_${entryIndex}`}>
                                                                    <li className="navlink">
                                                                        <NavLink to={entry.path}
                                                                                 style={{paddingLeft: 5}}>
                                                                            <i className="material-icons">{entry.icon}</i>
                                                                            {entry.name}
                                                                        </NavLink>
                                                                    </li>
                                                                </ScopedElement>
                                                            ))}
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <div className="divider" key={`divider_${category.name}`}
                                                     style={{margin: "10px 0"}}></div>
                                            </li>
                                        </ScopedElement>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                )}
            </Observer>
        );
    }
}
