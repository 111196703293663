import React, {Component} from "react"
import axios from "axios"
import {observer} from "mobx-react"
import Cookies from 'js-cookie'
import MesureStore from '../Appointments/Mesures/MesureStore'
import PrescriptionStore from "../Appointments/PrescriptionStore"
import TogglePriority from "../Orthoptistes/Paiements/TogglePriority";

import Loader from "../Shared/Loader";
import {Role} from "../Shared/Enums/Scope";
import ScopedElement from "../Shared/ScopedElement";
import Teletransmission from "../CarteVitale/Teletransmission";
import Validator from "../Shared/Validator";
import {toast} from "react-toastify";
import {Act, ActNature} from "../Shared/Enums/Enums";
import CancelButton from "../Orthoptistes/Appointment/CancelButton";
import {t} from "../I18n/i18n"
import EvaluationPrescription from "./EvaluationPrescription";
import ChainedActsRequest from "../CarteVitale/ChainedActs/ChainedActsRequest";

const _ = require('lodash');

@observer
class Reglement extends Component {
    constructor(props) {
        super(props);
        this.addActe = this.addActe.bind(this)
        this.prixTotal = this.prixTotal.bind(this)
        this.total = this.total.bind(this)
        this.deleteActe = this.deleteActe.bind(this)
        this.selectPayementMode = this.selectPayementMode.bind(this)
        this.state = {
            txt: "",
            loading: true,
            possibiltesPayement: [],
            possibiltesActes: [],
            actes: [],
            modePayementID: "",
            none: "",
            saved: true,
            priceBeforeValidation: 0
        };
    }

    addActe(e) {
        const selectedAct = this.state.possibiltesActes.find((act) => act.id === parseInt(e.target.value))
        // MesureStore.acte = selectedAct.titre
        MesureStore.selected_act = selectedAct
        this.setState({
            saved: false,
            actes: [selectedAct]
        }, () => this.selectPayementMode(e))
        MesureStore.actSaved(false)
    }

    selectPayementMode(e) {
        e.preventDefault()
        axios.post("/api/saveActesAndPayment", {
            payement_system_id: e.target ? e.target.value : null,
            actes: this.state.actes,
            appointement_id: MesureStore.appointement_id
        }).then((data) => {
            if (this.state.priceBeforeValidation === 0 && this.prixTotal() !== 0) MesureStore.updated()
            this.setState({saved: true, priceBeforeValidation: this.prixTotal()})
            MesureStore.actSaved(true)
            MesureStore.selected_act = this.state.actes[0]
            if (this.state.actes[0].nature === ActNature.NURSE.name) {
                MesureStore.unlocking_act = true
                toast.success("L'acte a bien été enregistré", {autoClose: 2000})
                return
            }
            if (this.prixTotal() === 0 && MesureStore.selected_act.nature === ActNature.TELEMEDICAL.name) {
                Cookies.set('last_appointements_data_done', "true")
                document.location = "/agenda/index"
            } else toast.success("L'acte a bien été enregistré", {autoClose: 2000})
        })
    }

    prixTotal() {
        var prix = 0
        this.state.actes.map((acte) => {
            prix += acte.prix
        })
        return (prix)
    }

    total() {
        return null
        // return (
        //     <tr>
        //         <td>Pour un total de</td>
        //         <td>{this.prixTotal()} €</td>
        //     </tr>
        // )
    }

    deleteActe(e) {
        e.preventDefault()
        let titre = e.target.id
        let actes = this.state.actes.filter(acte => acte.titre !== titre);

        this.setState({actes: actes, saved: false})
        MesureStore.actSaved(false)

    }

    componentDidMount() {
        axios.get("/api/getPossibilitesPayement", {params: {appointment_id: MesureStore.appointement_id}}).then((data) => {
            this.setState({
                possibiltesPayement: data.data[0],
                possibiltesActes: data.data[1],
                modePayementID: data.data[2],
                actes: data.data[3],
                loading: false
            }, () => {
                MesureStore.actSaved(this.state.actes.length > 0)
                this.setState({priceBeforeValidation: this.prixTotal()})
            })
        })
    }

    cancelAppointment = (e) => {
        const voidAct = this.state.possibiltesActes.find(acte => acte.prix === 0 && acte.nature !== ActNature.NURSE.name)
        this.setState({actes: [voidAct], saved: true}, () => this.selectPayementMode(e))
    }

    group_acts_by_nature = () => {
        const acts = this.state.possibiltesActes.filter((act) => MesureStore.displayable_act_natures.map(n => n.name).includes(act.nature))
            .sort((a, b) => a.titre > b.titre)
            .sort((a, b) => {
                if (a.titre.toLowerCase().includes("amy") && b.titre.toLowerCase().includes("amy")) {
                    return parseFloat(a.titre.toLowerCase().split("amy")[1]) - parseFloat(b.titre.toLowerCase().split("amy")[1])
                }
            }).reduce((acc, act) => {
                if (acc[act.nature]) acc[act.nature].push(act)
                else acc[act.nature] = [act]
                return acc
            }, {})
        // Order list by ActNature order
        return Object.values(ActNature).map(n => acts[n.name]).filter(a => a)
    }

    renderEvaluationPrescription = () => {
        const render = <div style={{marginBlock: 20}}>
            <EvaluationPrescription/>
        </div>
        if (!MesureStore.selected_act) return null
        console.log(MesureStore.selected_act)
        if ([Act.AMY8_AO, Act.AMY15_AO].includes(MesureStore.selected_act.titre)) return render
        if (MesureStore.selected_act.nature !== ActNature.ORTHOPTIC_EVALUATION.name) return null
        return render
    }

    render() {
        if (this.state.loading) {
            return <div id="full-height"
                        style={{width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Loader wide/>
            </div>
        } else {
            return <div style={{padding: 40, overflowY: "scroll"}}>
                <div className="row" style={{gap: 20}}>
                    <div className="col s5">
                        <h2 className="center">{t("REGULATION_PAGE.CHANGE_ACT")}</h2>
                        <select value={this.state.none} disabled={!_.isEmpty(MesureStore.selectedChain)}
                                onChange={this.addActe} className="browser-default" id="">
                            <option value="">{t("REGULATION_PAGE.CHOOSE")}</option>
                            {this.group_acts_by_nature().map((act_group) => {
                                const group_nature_name = ActNature[act_group[0]?.nature].short
                                return [
                                    <option disabled>——————— Actes pour {group_nature_name.toLowerCase()} :</option>,
                                    act_group.map((acte, index) => (
                                        <React.Fragment key={index}>
                                            {acte.titre.includes("SKIACOL") && !PrescriptionStore.hasSkiacol() ? (
                                                <option disabled value={index}>{acte.titre}</option>

                                            ) : (
                                                <React.Fragment>
                                                    {acte.titre.includes("RNM") && MesureStore.age >= 50 ? (
                                                        <option disabled value={acte.id}>{acte.titre} (age >
                                                            50)</option>
                                                    ) : (
                                                        <option value={acte.id}>{acte.titre}</option>
                                                    )
                                                    }
                                                </React.Fragment>
                                            )}
                                        </React.Fragment>
                                    ))
                                ]
                            })
                            }
                        </select>
                    </div>
                    <div className="col s4">
                        <h2 className="center">{t("REGULATION_PAGE.CURRENT_ACT")}</h2>
                        <table style={{border: "thin solid lightgrey"}}>
                            <tbody>
                            </tbody>
                            <tbody >
                            {this.state.actes.map((acte, index) => (
                                <tr key={index} style={{border: "none"}}>
                                    <td>{acte.titre}</td>
                                    <td>{acte.prix} €</td>
                                </tr>
                            ))}
                            {this.total()}
                            </tbody>
                        </table>
                        {this.renderEvaluationPrescription()}
                    </div>
                    <div className="col s3">
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <div style={{padding: "10px 0"}}>
                                <CancelButton name='reglement_cancel_button' id={MesureStore.appointement_id} wide
                                              small={false}/>
                            </div>
                            <ScopedElement scope={[Role.CV_USER]}>
                                <div style={{padding: "2vh 0"}}>
                                    <Teletransmission acts={this.state.actes}/>
                                </div>
                            </ScopedElement>
                        </div>
                    </div>
                    {/*<div className="col s3">*/}
                    {/*<TogglePriority key={MesureStore.act_saved} appointment_id={MesureStore.appointement_id}/>*/}
                    {/*</div>*/}
                </div>
            </div>
        }
    }
}

export default Reglement
