import React, {Component} from "react"
import axios from "axios"
import {toast} from 'react-toastify';
import Loader from "../Shared/Loader";
import {Act, ActNature, Sort} from "../Shared/Enums/Enums";
import Sorter from "../Shared/Sorter";
import moment from "moment";
import DatePicker from "../Shared/DatePicker";
import MultiSelector from "../Shared/MultiSelector";
import Tooltip from "../Shared/Tootip";
import CVButton from "./CVButton";
import PatientHandler from "./PatientHandler";
import ReportPrintButton from "../Orthoptistes/Reports/ReportPrintButton";
import CancelButton from "../Orthoptistes/Appointment/CancelButton";
import Helper from "../Shared/Helper";
import {t} from "../I18n/i18n"
import HelpFAB from "../Shared/Help/HelpFAB";

const _ = require('lodash');
const number_of_appt = 50

export default class ShowAgenda extends Component {
    constructor(props) {
        super(props);
        axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector("meta[name='csrf-token']").content
        this.renderData = this.renderData.bind(this)
        this.renderFilter = this.renderFilter.bind(this)
        this.renderLine = this.renderLine.bind(this)
        this.renderActs = this.renderActs.bind(this)
        this.renderStatus = this.renderStatus.bind(this)
        this.loadData = this.loadData.bind(this)
        this.expand = this.expand.bind(this)

        const params = new URLSearchParams(window.location.search);
        const doneParam = params.get('done') === 'true';
        const doneChecked = params.get('checked') === 'true';
        const doneUncomplete = params.get('uncomplete') === 'true';
        const doneAnalyse = params.get('analyse') === 'true';

        this.state = {
            data: [], count: 0, loading: true, expandedId: -1, offset: 0,
            filter: {
                done: doneParam,
                checked: doneChecked,
                uncomplete: doneUncomplete,
                analyse: doneAnalyse,
                name: "",
                end_date: undefined,
                start_date: undefined,
                possibleActs: []
            }, sort: {
                age: Sort.NONE, date: Sort.NONE, act: Sort.NONE, alerted: Sort.NONE, status: Sort.NONE, sms: Sort.NONE
            },
            cvState: "DISPLAY_FROM_CV"
        };
    }

    componentDidMount() {
        this.loadData()
    }

    changeOffset(int) {
        this.setState({
            offset: this.state.offset + int
        }, this.loadData)
    }

    updateURLParams() {
        const {filter} = this.state;
        const params = new URLSearchParams(window.location.search);

        params.set('done', filter.done);
        if (filter.checked) {
            params.set('checked', filter.checked);
        } else {
            params.delete('checked');
        }
        if (filter.uncomplete) {
            params.set('uncomplete', filter.uncomplete);
        } else {
            params.delete('uncomplete');
        }

        const newUrl = `${window.location.pathname}?${params.toString()}`;
        window.history.pushState({}, '', newUrl);
    }


    sort(element) {
        this.setState({
            sort: Object.assign({}, this.state.sort, {
                age: element === "age" ? (this.state.sort.age !== Sort.DOWN ? Sort.DOWN : Sort.UP) : Sort.NONE,
                date: element === "date" ? (this.state.sort.date !== Sort.DOWN ? Sort.DOWN : Sort.UP) : Sort.NONE,
                act: element === "act" ? (this.state.sort.act !== Sort.DOWN ? Sort.DOWN : Sort.UP) : Sort.NONE,
                alerted: element === "alerted" ? (this.state.sort.alerted !== Sort.DOWN ? Sort.DOWN : Sort.UP) : Sort.NONE,
                status: element === "status" ? (this.state.sort.status !== Sort.DOWN ? Sort.DOWN : Sort.UP) : Sort.NONE,
                sms: element === "sms" ? (this.state.sort.sms !== Sort.DOWN ? Sort.DOWN : Sort.UP) : Sort.NONE
            })
        }, () => this.setState({
            data: this.state.data.sort((a, b) => {
                if (this.state.sort.age !== Sort.NONE) {
                    return this.state.sort.age === Sort.UP ? (a.patient_age > b.patient_age ? 1 : -1) : (a.patient_age < b.patient_age ? 1 : -1)
                } else if (this.state.sort.date !== Sort.NONE) {
                    const d1 = moment(a.start, "DD-MM-YYYY hh:mm")
                    const d2 = moment(b.start, "DD-MM-YYYY hh:mm")
                    return this.state.sort.date === Sort.UP ? (d1.isAfter(d2) ? 1 : -1) : (!d1.isAfter(d2) ? 1 : -1)
                } else if (this.state.sort.act !== Sort.NONE) {
                    return this.state.sort.act === Sort.UP ? (a.acts.map(act => act.name).includes(Act.RNM) ? 1 : -1) : (!a.acts.map(act => act.name).includes(Act.RNM) ? 1 : -1)
                } else if (this.state.sort.alerted !== Sort.NONE) {
                    return this.state.sort.alerted === Sort.UP ? (a.alerted ? 1 : -1) : (!a.alerted ? 1 : -1)
                } else if (this.state.sort.status !== Sort.NONE) {
                    return this.state.sort.status === Sort.UP ? (a.status >= 2 ? 1 : -1) : (a.status < 2 ? 1 : -1)
                } else if (this.state.sort.sms !== Sort.NONE) {
                    return this.state.sort.sms === Sort.UP ? (a.sms ? 1 : -1) : (!a.sms ? 1 : -1)
                } else {
                    return !moment(a.start, "DD-MM-YYYY hh:mm").isAfter(moment(b.start, "DD-MM-YYYY hh:mm"))
                }
            })
        }))
    }

    expand(bool, index) {
        this.setState({expandedId: bool ? index : -1})
    }

    computeColors = (appointments) => {
        const colors = ["blue", "green", "red", "yellow", "grey", "cyan", "teal", "orange", "purple", "brown", "amber", "indigo", "lime"].reverse()
        let palette = {null: "white"}
        appointments.map((appt) => {
            if (!palette[appt.motive]) {
                palette[appt.motive] = colors.length > 0 ? colors.pop() : "white"
            }
        })
        this.setState({palette: palette})
    }

    loadData() {
        this.setState({loading: true, expandedId: -1})
        axios.get("/api/appointements/filtered", {
            params: {
                done: this.state.filter.done,
                checked: this.state.filter.checked && this.state.filter.done,
                start_date: this.state.filter.start_date === undefined ? null : Helper.formatDate(this.state.filter.start_date, "YYYY-MM-DD"),
                end_date: this.state.filter.end_date === undefined ? null : Helper.formatDate(this.state.filter.end_date, "YYYY-MM-DD"),
                possibleActs: this.state.filter.possibleActs,
                uncomplete: this.state.filter.uncomplete,
                analyse: this.state.filter.analyse,
                number: number_of_appt,
                offset: this.state.offset
            }
        }).then((data) => {
            this.computeColors(data.data.appointments)
            this.setState({
                data: data.data.appointments, count: data.data.totalCount, loading: false
            })
        }).catch(err => {
            toast.error(t("SHOW_AGENDA.TOAST_ERROR_LOAD"))
            this.setState({
                loading: false
            })
        })
    }

    createTestAppointment = () => {
        axios.put("/api/appointements/test").then((data) => {
            this.loadData()
        }).catch(err => {
            toast.error(t("SHOW_AGENDA.TOAST_ERROR_CREATE"))
        })
    }

    renderFilter() {
        return (<div style={{padding: 20, display: "flex", alignItems: "center", gap: 20}}>
            <label>
                <input id="done" type="checkbox" className="filled-in"
                       checked={this.state.filter.done}
                       onChange={e => this.setState({
                           offset: 0,
                           filter: {...this.state.filter, done: e.target.checked}
                       }, () => {
                           this.loadData();
                           this.updateURLParams();
                       })}
                />
                <span>{t("SHOW_AGENDA.HISTORY")}</span>
            </label>
            <div className={!this.state.filter.done ? "hide" : ""}
                 style={{display: "flex", alignItems: "center", gap: 20}}>
                <label>
                    <input id="checked" type="checkbox" className="filled-in"
                           checked={this.state.filter.checked}
                           onChange={e => this.setState({
                               filter: {...this.state.filter, checked: e.target.checked}
                           }, () => {
                               this.loadData();
                               this.updateURLParams();
                           })}/>
                    <span>{t("SHOW_AGENDA.VALIDATE_CONSULT")}</span>
                </label>
                <label>
                    <input id="uncomplete" type="checkbox" className="filled-in"
                           checked={this.state.filter.uncomplete}
                           onChange={e => this.setState({
                               filter: {...this.state.filter, uncomplete: e.target.checked}
                           }, () => {
                               this.loadData();
                               this.updateURLParams();
                           })}/>
                    <span>{t("SHOW_AGENDA.INCOMPLETE_CONSULT")}</span>
                </label>

                <label>
                    <input id="uncomplete" type="checkbox" className="filled-in"
                           checked={this.state.filter.analyse}
                           onChange={e => this.setState({
                               filter: {...this.state.filter, analyse: e.target.checked}
                           }, () => {
                               this.loadData();
                               this.updateURLParams();
                           })}/>
                    <span>{t("SHOW_AGENDA.VALIDATION_IN_PROGRESS")}</span>
                </label>

                <DatePicker txt={t("SHOW_AGENDA.BETWEEN")} date={this.state.filter.start_date}
                            id="after"
                            onChange={v => {
                                this.setState({
                                    filter: Object.assign({}, this.state.filter, {start_date: v})
                                }, this.loadData);
                            }}
                />
                <DatePicker txt={t("AND")} date={this.state.filter.end_date}
                            id="before"
                            onChange={v => {
                                this.setState({
                                    filter: Object.assign({}, this.state.filter, {end_date: v})
                                }, this.loadData);
                            }}/>
                <MultiSelector txt={t("SHOW_AGENDA.FILTER_BY_ACT")}
                               data={Object.values(Act).map(act => {
                                   return {text: act, id: act}
                               })}
                               onChange={arr => this.setState({
                                   filter: Object.assign({}, this.state.filter, {possibleActs: arr})
                               }, this.loadData)}/>
            </div>

        </div>)
    }

    renderActs(acts) {
        return (acts.map((act, index) => {
            return <span key={index}>{act.name}</span>
        }))
    }

    renderAlert(alerted) {
        if (alerted === true) {
            return (<div className="truncate"><i className="material-icons left red-text">warning</i>
                <div className="hide-on-med-and-down">{t("ALERT")}</div>
            </div>)
        }
    }

    renderStatus(appointment) {
        const status = appointment.status
        const acts = appointment.acts

        if (status === null || status === undefined || appointment.done === false) {
            return null
        }
        if (!_.isEmpty(acts) && ![ActNature.TELEMEDICAL.name, ActNature.NURSE.name].includes(acts[0].nature)) {
            return <div className="truncate"><i className="material-icons left green-text">done</i>
                <div className="hide-on-med-and-down">{t("CONSULT_COMPLETED")}</div>
            </div>
        }
        if (acts.length === 1 && acts[0].name.trim() === Act.NOTHING) {
            return <div className="truncate"><i className="material-icons left grey-text">cancel</i>
                <div className="hide-on-med-and-down">{t("CANCELED")}</div>
            </div>
        }
        if (status === 0) {
            return <div className="truncate"><i className="material-icons left grey-text">announcement</i>
                <div className="hide-on-med-and-down">{t("INCOMPLETE")}</div>
            </div>
        }
        if (status > 1) {
            return <div className="truncate"><i className="material-icons left green-text">done</i>
                <div className="hide-on-med-and-down">{t("VALIDATED")}</div>
            </div>
        }
        return <div className="truncate"><i className="material-icons left orange-text">pending</i>
            <div className="hide-on-med-and-down">{t("VALIDATE_IN_PROGRESS")}</div>
        </div>
    }

    renderLine(appt, index) {
        const expanded = index === this.state.expandedId
        return (<div>
            <div className="row left-align valign-wrapper white"
                 style={{margin: 0, padding: "0 10px"}}>
                <div className="col xl2 s4 left-align">
                    <div>
                        <a href={`/patients/${appt.patient_id}`}><h6
                            style={{fontWeight: "bold"}}>{appt.patient_firstname} {appt.patient_lastname.toUpperCase()}</h6>
                        </a>
                        <h6>{appt.patient_phone || <br/>}</h6>
                        <h6>{appt.patient_email || <br/>}</h6>
                    </div>
                </div>
                <div className="col s1 left-align">
                    <h6>{appt.patient_age} {t("YEARS_OLD")}</h6>
                    <h6>{appt.patient_gender}</h6>
                </div>
                <div className="col xl1 s2">{appt.start}</div>
                <div className={`col s1`}>
                    <div className={appt.done ? "" : "hide"}>
                        {this.renderActs(appt.acts)}
                    </div>
                </div>
                <div className={`col xl2 s1`}>
                    <div className={appt.done ? "" : "hide"}>
                        {this.renderAlert(appt.alerted)}
                    </div>
                </div>
                <div className={`col xl2 s1`}>
                    <div className={appt.done ? "" : "hide"}>
                        {this.renderStatus(appt)}
                    </div>
                </div>
                <div className={`col xl2 s1 right-align`}>{appt.done ? null :
                    <div className="col"><Tooltip id={`new-consult-agenda-${index}`}
                                                  text={`${t("SHOW_AGENDA.START_CONSULT_WITH")} ${appt.patient_firstname} ${appt.patient_lastname.toUpperCase()}.`}>
                        <a className="btn blue darken-2 truncate z-depth-0" href={`/mesure/${appt.id}`}><i
                            className="material-icons left">play_arrow</i>{t("SHOW_AGENDA.START_CONSULT")}</a></Tooltip>
                    </div>}
                </div>
                <div className="col xl1 s1 center-align">
                    <a id="hoverable" className="btn-flat transparent black-text"
                       onClick={e => this.expand(!expanded, index)}><i
                        className="material-icons">{expanded ? "expand_less" : "expand_more"}</i></a>
                </div>
            </div>
            <div>
                {expanded ? (<div className="white">
                    <div className="divider"/>
                    <div style={{padding: "2vh 1vw 1vh 0", marginBottom: "0", display: "flex"}}>
                        {appt.checked ? (
                            <a className={`btn btn-small blue darken-2 z-depth-0 ${appt.acts.map(act => act.name).includes(Act.RNM) ? "" : "disabled"}`}
                               href={`/patients/rapports/rapports?appointment_id=${appt.id}&patient_id=${appt.patient_id}`}
                               style={{margin: "0 1vw"}}>{t("SHOW_AGENDA.SEE_RNM_REPORT")}</a>) : (<div/>)}
                        <a className="btn btn-small blue darken-2 z-depth-0" href={`/patients/${appt.patient_id}`}
                           style={{margin: "0 1vw"}}>{t("SHOW_AGENDA.PATIENT_FILE")}</a>
                        <a className="btn btn-small blue darken-2 z-depth-0" href={`/patients/${appt.patient_id}/edit`}
                           style={{margin: "0 1vw"}}>{t("SHOW_AGENDA.EDIT_PATIENT")}</a>
                        <div className="center" style={{width: "30%"}}>{appt.motive}</div>

                        {appt.done ? (
                            <div style={{marginLeft: "auto", display: "flex"}}>
                                <div style={{padding: "0 1vw"}}>
                                    <ReportPrintButton appointment_id={appt.id}
                                                       disabled={appt.selected_template_id === null}
                                    />
                                </div>
                                <div style={{padding: "0 1vw"}}>
                                    <a className={`btn btn-small blue darken-2 z-depth-0 ${appt.checked === true ? "disabled" : ""}`}
                                       href={`/mesure/${appt.id}`}>{t("SHOW_AGENDA.EDIT_CONSULT")}</a>
                                </div>
                            </div>
                        ) : (
                            <div style={{marginLeft: "auto", display: "flex"}}>
                                <div style={{padding: "0 1vw"}}>
                                    <CancelButton name="agenda_cancel_button" id={appt.id}
                                                  onCancel={() => this.setState({
                                                      expandedId: undefined,
                                                      data: this.state.data.filter(el => el.id !== appt.id)
                                                  })}/>
                                </div>
                            </div>
                        )}
                    </div>
                </div>) : (<div/>)}
            </div>
        </div>)
    }

    renderData() {
        if (this.state.data.length !== 0) {
            const appointments = this.state.data.filter((appt) => {
                return (this.state.filter.name === "" || (appt.patient_firstname !== null && appt.patient_firstname.toLowerCase().includes(this.state.filter.name.toLowerCase())) || (appt.patient_lastname !== null && appt.patient_lastname.toLowerCase().includes(this.state.filter.name.toLowerCase())) || (appt.patient_phone !== null && appt.patient_phone.toLowerCase().includes(this.state.filter.name.toLowerCase())) || (appt.patient_email !== null && appt.patient_email.toLowerCase().includes(this.state.filter.name.toLowerCase())))
            })
            return (<div>
                <div className="valign-wrapper" style={{justifyContent: "center"}}>
                    <a id="hoverable" className={`btn-flat large-btn ${this.state.offset === 0 ? "hide" : ""}`}
                       onClick={(e) => this.changeOffset(-1)}><i className="material-icons">chevron_left</i></a>
                    <div className="valign-wrapper" style={{gap: 20, alignItems: "center"}}>
                        <h1>{this.state.filter.done ? t("SHOW_AGENDA.CONSULTATION_HISTORY") : t("SHOW_AGENDA.UPCOMING_CONSULT")}</h1>
                        <a className={`btn-small blue darken-2 z-depth-0 ${this.props.test_user && !this.state.filter.done ? "" : "hide"}`}
                           onClick={this.createTestAppointment}
                        >
                            <i className="material-icons left">add_task</i>
                            {t("SHOW_AGENDA.CREATE_TEST_CONSULT")}
                        </a>
                    </div>
                    <a id="hoverable"
                       className={`btn-flat large-btn ${(this.state.offset + 1) * number_of_appt >= this.state.count ? "hide" : ""}`}
                       onClick={(e) => this.changeOffset(1)}><i className="material-icons">chevron_right</i></a>
                </div>
                <div className="valign-wrapper" style={{justifyContent: "center"}}>
                    <h6>{`(${this.state.offset * number_of_appt} - ${(this.state.offset + 1) * number_of_appt > this.state.count ? this.state.count : (this.state.offset + 1) * number_of_appt} / ${this.state.count})`}</h6>
                </div>
                <ul className="collection" style={{borderRadius: "20px"}}>
                    <div className="row valign-wrapper left-align" style={{margin: 0}}>
                        <div className="col xl2 s4">
                            <div className="input-field">
                                <input type="text" value={this.state.filter.name}
                                       onChange={(e) => this.setState({
                                           filter: Object.assign({}, this.state.filter, {name: e.target.value})
                                       })}
                                />
                                <label>{t("SEARCH")}</label>
                            </div>
                        </div>
                        <div className="col s1"><Sorter state={this.state.sort.age}
                                                        onClick={() => this.sort("age")}>{t("AGE")}</Sorter></div>
                        <div className="col xl1 s2"><Sorter state={this.state.sort.date}
                                                            onClick={() => this.sort("date")}>{t("BEGINNING")}</Sorter>
                        </div>
                        <div className={`col s1 ${this.state.filter.done ? "" : "hide"}`}><Sorter
                            state={this.state.sort.act}
                            onClick={() => this.sort("act")}>{t("ACTS")}</Sorter></div>
                        <div className={`col xl2 s1 ${this.state.filter.done ? "" : "hide"}`}><Sorter
                            state={this.state.sort.alerted}
                            onClick={() => this.sort("alerted")}>{t("ALERT")}</Sorter>
                        </div>
                        <div className={`col xl4 s2 ${this.state.filter.done ? "" : "hide"}`}><Sorter
                            state={this.state.sort.status}
                            onClick={() => this.sort("status")}>{t("STATUS")}</Sorter>
                        </div>
                        <div className="col s1"></div>
                    </div>
                    <div className="divider"/>
                    {appointments.map((rdv, index) => {
                        const colorStyle = rdv.color ? {
                            backgroundColor: rdv.color,
                            opacity: 0.8
                        } : {}
                        const colorClassname = rdv.color ? "" : `${this.state.palette[rdv.motive]} lighten-3`
                        return <li key={index} className={`collection-item`}
                                   style={{padding: "0px", display: "flex"}}>
                            <Tooltip id={`tooltip-motive-agenda-${index}-${this.state.filter.done}`}
                                     text={rdv.motive} disabled={!rdv.motive}>
                                <div style={{width: "20px", ...colorStyle}}
                                     className={colorClassname}/>
                            </Tooltip>
                            <div style={{width: "100%"}}>
                                {this.renderLine(rdv, index)}
                            </div>
                        </li>
                    })}
                </ul>
            </div>)
        } else {
            return (
                <div>
                    <div className="valign-wrapper" style={{gap: 20, justifyContent: "center"}}>
                        <h1> {this.state.filter.done ? t("SHOW_AGENDA.NO_CONSULTATION_FIND") : t("SHOW_AGENDA.NO_CONSULTATION")}</h1>
                        <a className={`btn-small blue darken-2 z-depth-0 ${this.props.test_user ? "" : "hide"}`}
                           onClick={this.createTestAppointment}
                        >
                            <i className="material-icons left">add_task</i>
                            {t("SHOW_AGENDA.CREATE_TEST_CONSULT")}
                        </a>
                    </div>
                    <h6 className="center-align">{this.state.filter.done ? "" : t("SHOW_AGENDA.DESYNCRONISED_AGENDA")}</h6>
                </div>)
        }

    }

    renderCvModal(data) {
        return <PatientHandler
            data={data}
            agenda={this.state.data}
            state={this.state.cvState}
            changeState={(state) => this.setState({cvState: state})}
        />
    }

    render() {
        return (<div>
            <div style={{paddingTop: "2vh"}}>
                <div>
                    {this.renderFilter()}
                </div>
                <div style={{paddingLeft: "2vh"}}>
                    {this.state.filter.done ? <div/> :
                        <CVButton
                            modalRender={(data) => this.renderCvModal(data, this.state.cvState)}
                            onClick={() => this.setState({cvState: "DISPLAY_FROM_CV"})}
                            bridge_uid={this.props.bridge_uid}
                        />}
                </div>
                <div style={{paddingLeft: "2vw", paddingRight: "2vw"}}>
                    {this.state.loading ?
                        <div style={{paddingTop: "10vh", width: "100%", display: "flex", justifyContent: "center"}}>
                            <Loader wide/></div> : this.renderData()}
                </div>
                <HelpFAB url="https://www.temeoo.fr/documentation#demarrer-une-consultation"/>
            </div>
        </div>);
    }
}
