import React, {useState} from "react";
import moment from "moment";
import {Act, ActNature} from "../../Shared/Enums/Enums";
import Tooltip from "../../Shared/Tootip";
import axios from "axios";
import EditAppointmentButton from "./EditAppointmentButton";
import StatusIcon from "../../Appointments/StatusIcon";
import ResponsiveMoreButton from "../../Shared/UiKit/ResponsiveMoreButton";
import ExamRecapTabs from "./ExamRecapTabs";
import AskForReworkButton from "../../ophtalmologiste/Appointment/AskForReworkButton";
import {t} from "../../I18n/i18n"
import AskForReworkButtonOrtho from "../../Orthoptistes/Appointment/AskForReworkButtonOrtho";

function ExamHistory({data, orthoptiste_signed, level_2}) {
    const [appointments, setAppointments] = useState(data.appointments)
    const [expandedAppointments, setExpandedAppointment] = useState([])
    const [filter, setFilter] = useState({
        expanded: false,
        show_canceled: true,
        act_types: [ActNature.TELEMEDICAL, ActNature.ORTHOPTIC_EVALUATION, ActNature.REEDUCATION, ActNature.FUNCTIONAL_EXPLORATION]
    })

    let csrfToken = document.querySelector("meta[name='csrf-token']").content
    axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

    const toggle_lock = (id) => {
        axios.post(`/admin/appointement/toggle_override_lock?id=${id}`).then(() => {
            setAppointments(appointments.map(appointement => {
                if (appointement.id === id) {
                    appointement.override_lock = !appointement.override_lock
                }
                return appointement
            }))
        })
    }

    const archive_appointment = (id) => {
        axios.patch(`/patients/appointment/archive/${id}`).then((resp) => {
            setAppointments(appointments.filter(appointement => appointement.id !== resp.data.id))
        })
    }

    const handleExpandedAppointments = (appointment_id) => {
        if (expandedAppointments.includes(appointment_id)) {
            let temp_array = expandedAppointments.filter((appointment) => appointment !== appointment_id)
            setExpandedAppointment(temp_array)
        } else {
            setExpandedAppointment([...expandedAppointments, appointment_id])
        }
    }

    const filterAppointments = (appointments) => {
        return appointments.filter(appointment => {
            const act = data.actepossibles.find(act => act.id === appointment.id)
            if (!filter.show_canceled && (!act || act.titre.trim() === Act.NOTHING)) return false
            if (!act) return true
            if([ActNature.NURSE, ActNature.SCREENING].map(n => n.code).includes(act.nature)) return true
            const filter_natures = filter.act_types.map(act => act.code)
            return filter_natures.includes(act.nature);

        })
    }

    function renderActionsButtons(appointment, act) {
        const reworkable = ([2, 3].includes(appointment.status_controle))
            && act?.titre !== Act.ONLY_PRESCRITPION
            && appointment.orthoptiste_lastname !== null
        const isAbsent = act?.titre.trim() === Act.NOTHING || appointment.status_controle <= 0
        if (orthoptiste_signed) {
            const prescriptionButtons = appointment.prescription_text ? [
                <a className="btn-small blue darken-2 z-depth-0"
                   href={`/orthoptiste/download/prescription?appointment_id=${appointment.id}`}
                   target="_blank"><i className="material-icons left">picture_as_pdf</i>{t("EXAM_HISTORY.DOWNLOAD.SCOR_PDF")}</a>,
                <a className="btn-small blue darken-2 z-depth-0"
                   href={`/orthoptiste/download/prescription?html=1&appointment_id=${appointment.id}`}
                   target="_blank"><i className="material-icons left">image</i>{t("EXAM_HISTORY.DOWNLOAD.SCOR_IMG")}</a>
            ] : []
            return <div className="valign-wrapper" style={{marginLeft: 20}}>
                <EditAppointmentButton orthoptist_signed={orthoptiste_signed}
                                       appointment_id={appointment.id}
                                       appointment_status={appointment.status_controle}
                />
                {reworkable ? <AskForReworkButtonOrtho reworkable={reworkable}
                                                       appointment_id={appointment.id}
                                                       onValidation={(id) => {
                                                           setAppointments((prevAppointments) => prevAppointments.map(appointement => {
                                                               if (appointement.id === id) {
                                                                   appointement.status_controle = 0
                                                               }
                                                               return appointement
                                                           }))
                                                       }}
                /> : null}
                <div style={{flex: 1}}>
                    <ResponsiveMoreButton id={`patient_history_appt_${appointment.id}_btns`} options={[
                        <a className={`btn-small green darken-2 z-depth-0 ${appointment.document_count || "disabled"} `}
                           target="_blank" href={`documents/${appointment.id}`}>
                            {appointment.document_count > 1 ?
                                `${appointment.document_count} ${t("EXAM_HISTORY.DOCUMENTS_AVAILABLE")}`
                                :
                                `${appointment.document_count} ${t("EXAM_HISTORY.DOCUMENT_AVAILABLE")}`}
                        </a>,
                        <a className="btn-small blue darken-2 z-depth-0"
                           href={`/syntheses/rendez-vous/${appointment.id}`}
                           target="_blank"
                           title="Consulter la synthèse du rendez-vous associé.">
                            <i className="material-icons left hide-on-med-and-down">assignment</i>
                            <i className="material-icons hide-on-large-only">assignment</i>
                            <span
                                className="hide-on-med-and-down">{t("EXAM_HISTORY.CONSULT_SUMMARY")}</span>
                        </a>,
                        isAbsent ? <a className={`btn-small red darken-2 z-depth-0 ${isAbsent ? "hide" : ""}`}
                                      onClick={() => archive_appointment(appointment.id)}>
                            <i className="material-icons left hide-on-med-and-down">delete</i>
                            <i className="material-icons hide-on-large-only">delete</i>
                            <span className="hide-on-med-and-down">{t("DELETE")}</span>
                        </a> : null,
                        ...prescriptionButtons
                    ]}/>
                </div>

                <a className="btn-small btn-flat transparent"
                   onClick={() => handleExpandedAppointments(appointment.id)}>
                    <i className={`material-icons more-button ${expandedAppointments.includes(appointment.id) ? "flipped" : ""}`}>expand_more</i>
                </a>
            </div>
        }

        if (level_2) return <div className="valign-wrapper" style={{gap: 10, width: "100%"}}>
            <div>
                {appointment.status_controle === 3 ?
                    <Tooltip
                        id={`tooltipped_locked_button_${appointment.id}`}
                        text={`${appointment.override_lock ? t("EXAM_HISTORY.BLOCK_ORDO") : t("EXAM_HISTORY.UNBLOCK_ORDO")}`}>
                        <i id="hoverable" style={{cursor: "pointer"}}
                           className="material-icons black-text"
                           onClick={() => toggle_lock(appointment.id)}>{appointment.override_lock ? "lock_open" : "lock"}</i>
                    </Tooltip>
                    : <div style={{paddingRight: 24, height: "100%"}}/>
                }
            </div>
            <AskForReworkButton reworkable={reworkable}
                                appointment_id={appointment.id}
                                onValidation={(id) => {
                                    setAppointments((prevAppointments) => prevAppointments.map(appointement => {
                                        if (appointement.id === id) {
                                            appointement.status_controle = 0
                                        }
                                        return appointement
                                    }))
                                }}
            />
            <div style={{flex: 1}}>
                <ResponsiveMoreButton id={`patient_history_appt_${appointment.id}_btns`} options={[
                    <a className={`btn-small blue darken-2 z-depth-0 ${isAbsent ? "disabled" : ""}`}
                       href={`/admin/controle_rdv/${appointment.id}`}>
                        <i className="material-icons left hide-on-med-and-down">find_in_page</i>
                        <i className="material-icons hide-on-large-only">find_in_page</i>
                        <span>Contrôle prescription</span>
                    </a>,
                    <a className={`btn-small green darken-2 z-depth-0 ${appointment.document_count || "disabled"} `}
                       target="_blank" href={`documents/${appointment.id}`} style={{width: 190}}
                    >
                        {appointment.document_count} document{appointment.document_count > 1 ? "s" : ""} disponible{appointment.document_count > 1 ? "s" : ""}
                    </a>,
                    <a className="btn-small blue darken-2 z-depth-0"
                       href={`/syntheses/rendez-vous/${appointment.id}`}
                       target="_blank"
                       title="Consulter la synthèse du rendez-vous associé.">
                        <i className="material-icons left hide-on-med-and-down">assignment</i>
                        <i className="material-icons hide-on-large-only">assignment</i>
                        <span
                            className="hide-on-med-and-down">{t("EXAM_HISTORY.CONSULT_SUMMARY")}</span>
                    </a>
                ]}/>
            </div>
        </div>

        return null
    }

    const renderAppointment = (appointment, index) => {
        if (!appointment.done) return <tr>{renderNewAppointment()}</tr>
        const act = data.actepossibles.find(act => act.id === appointment.id)
        let tooltipText = appointment.comment ? `Anamnèse :\n${appointment.comment}` : t("EXAM_HISTORY.WITHOUT_ANAMNESIS")
        if (appointment.private_comment) tooltipText += `\n\nCommentaire privé :\n${appointment.private_comment}`
        return <>
            <Tooltip position="left"
                     key={appointment.id}
                     text={tooltipText}
                     id={`tooltip_detailed_appt_${index}`}>
                <tr key={appointment.id} data-cy="FirstPartAppointment">
                    <td>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-evenly'
                        }}>
                            {appointment.need_to_pay ?
                                <i className="material-icons blue-text text-darken-2"
                                   title="Le paiement HN est en attente"
                                >euro</i>
                                : null
                            }
                            <StatusIcon status={appointment.status_controle} act={act}/>
                        </div>
                    </td>
                    <td style={{textAlign: 'center'}}>
                        {moment(appointment.start).format("DD/MM/YYYY hh:mm")}
                    </td>
                    <td className="center">
                        {act ? act.titre : "Aucun acte associé"}
                    </td>
                    {appointment.orthoptiste_lastname === null ?
                        <td>/</td>
                        :
                        <td style={{textAlign: 'center'}}>{Helper.capitalizeFirstLetter(appointment.orthoptiste_lastname)}</td>}
                    <td>
                        {renderActionsButtons(appointment, act)}
                    </td>
                </tr>
            </Tooltip>
            <tr data-cy="SecondPartAppointment">
                {expandedAppointments.includes(appointment.id) ?
                    <ExamRecapTabs appointment={appointment}/>
                    : null}
            </tr>
        </>

    }

    const renderNewAppointment = () => {
        const appointments_todo = appointments.filter(appt => !appt.done)
        if (appointments_todo.length === 0) return null

        const todo = appointments_todo[0]

        return <>
            <td className="center" style={{width: "10%"}}><i className="material-icons black-text"
                                                             title="Nouvel examen">start</i></td>
            <td className="center" style={{width: "15%"}}>{Helper.formatDate(todo.start, "DD/MM/YYYY hh:mm")}</td>
            <td className="center" style={{width: "15%"}}>Nouvel examen</td>
            <td className="center" style={{width: "20%"}}></td>
            <td className="center" style={{width: "50%"}}>
                <a className="btn btn-small green darken-2 z-depth-0"
                   href={`/mesure/${todo.id}`} title={`Démarrer la consultation`}>
                    <i className="material-icons left">play_arrow</i>
                    Démarrer consultation
                </a>
            </td>
        </>
    }

    const renderFilter = () => {
        if (!filter.expanded) return null
        return <div className="valign-wrapper" style={{gap: 20, marginTop: 20}}>
            {[ActNature.TELEMEDICAL, ActNature.ORTHOPTIC_EVALUATION, ActNature.REEDUCATION, ActNature.FUNCTIONAL_EXPLORATION].map((act_type, index) => {
                const toggle = () => {
                    if (filter.act_types.includes(act_type)) {
                        setFilter(prevState => {
                            return {...prevState, act_types: prevState.act_types.filter(act => act !== act_type)}
                        })
                    } else {
                        setFilter(prevState => {
                            return {...prevState, act_types: [...prevState.act_types, act_type]}
                        })
                    }
                }
                return <label>
                    <input type="checkbox" className="filled-in"
                           checked={filter.act_types.includes(act_type)}
                           onChange={toggle}/>
                    <span>{act_type.short}</span>
                </label>
            })}
            <label>
                <input type="checkbox" className="filled-in"
                       checked={filter.show_canceled}
                       onChange={() => setFilter(prevState => {
                           return {...prevState, show_canceled: !prevState.show_canceled}
                       })}/>
                <span>{t("EXAM_HISTORY.EXAM_NOT_PERFORMED")}</span>
            </label>
        </div>
    }

    return <div className="card" style={{gap: 10, height: '100%', display: "flex", flexDirection: "column"}}
                data-cy="ExamHistoryComponent">
        <div className="valign-wrapper">
            <div style={{flex: 1}}>
                <h2 className="center" style={{marginTop: 11, marginBottom: 10}}>{t("EXAM_HISTORY.EXAM_HISTORY_TITLE")}</h2>
            </div>
            <a className="btn-flat btn-small transparent z-depth-0" onClick={() => setFilter(prevState => {
                return {...prevState, expanded: !prevState.expanded}
            })}>
                <i className="material-icons">filter_list</i>
            </a>
        </div>
        <div className="divider"/>
        <div style={{marginInline: 20}}>
            {renderFilter()}
        </div>
        <div style={{flex: "1 1 0", overflowY: "scroll"}}>
            {appointments.length > 0 ? (
                <table className="highlight">
                    <thead>
                    <tr>
                        <th className="center" style={{width: "10%"}}>{t("EXAM_HISTORY.STATE")}</th>
                        <th className="center" style={{width: "15%"}}>{t("DATE")}</th>
                        <th className="center" style={{width: "15%"}}>{t("EXAM_HISTORY.TYPE_OF_EXAM")}</th>
                        <th className="center" style={{width: "10%"}}>{t("ORTHOPTIST")}</th>
                        <th className="center" style={{width: "50%"}}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {filterAppointments(appointments).map((appointment, index) => renderAppointment(appointment, index))}
                    </tbody>
                </table>
            ) : <p className="center">{t("EXAM_HISTORY.WITHOUT_EXAM")}</p>}
        </div>
        {orthoptiste_signed ? (
            <div className="center" style={{width: "100%"}}>
                <a className="btn btn-small blue darken-2 z-depth-0"
                   title={`Lancer une nouvelle consultation`}
                   href={`/NewAppointement/${data.patient.id}`}>
                    <i className="material-icons left">add</i>{t("EXAM_HISTORY.NEW_CONSULTATION")}
                </a>
            </div>
        ) : null}
    </div>
}

export default ExamHistory