import React from "react";
import {BillingCategory} from "../../../Shared/Enums/Enums";

function PricesOverview({prices, fixed, variable, pricing}) {

    const renderBadge = (value, tooltip) => {
        let color = "blue";
        if (value === true) {
            [color, value] = ["green", "Actif"]
        } else if (value === false) {
            [color, value] = ["red", "Inactif"]
        }
        return <>
            <span className={`badge ${color} darken-2 white-text`} style={{borderRadius: 5, minWidth: 80}}>{value}</span>
            {tooltip ? <i className="material-icons tiny" title={tooltip} style={{cursor: "help"}}>info</i> : null}
        </>
    }
    const renderPricing = () => {
        return <>
            <h4 className="center">Mes tarifs</h4>
            <label>Les tarifs sont fixés par nos soins, aucune modification ne sera faite sans vous prévenir au
                préalable</label>
            <h6 className="valign-wrapper">
                <i className="material-icons left blue-text text-darken-2">local_atm</i>
                Forfait : {fixed} €
            </h6>
            <h6 className="valign-wrapper"
                title={`Une part variable peut être appliquée pour diminuer ou augmenter la facture.
Cette somme est remise à 0 après chaque facture.
Toute modification de cette valeur ne se fera pas sans vous en informer au préalable.`}>
                <i className="material-icons left blue-text text-darken-2">price_change</i>
                Part variable : {variable} €
            </h6>
        </>
    }

    const renderOptions = () => {
        const category = Object.entries(BillingCategory).find(([key, value]) => value.key === pricing.category)?.[1]?.name ?? pricing.category
        return <>
            <h4 className="center">Mes options</h4>
            <label>Les options actuellement en vigueur, joignez-nous pour en changer.</label>
            <div style={{display: "flex", flexDirection: "column", gap: 10}}>
                <h5 style={{marginBlock: 10}}><strong>Mon forfait</strong> : {category ?? "Sur mesure"}</h5>
                <div className="valign-wrapper" style={{gap: 20}}>
                    {renderBadge(pricing.telemedicine_acts_enabled, "Réaliser des actes de télémédecine en partenaria avec un ophtalmologiste")}
                    <h6 style={{flex: 1, margin: 0}}>Télémédecine</h6>
                </div>
                <div className="valign-wrapper" style={{gap: 20}}>
                    {renderBadge(pricing.orthoptic_acts_enabled, "Réaliser des actes d'orthoptie")}
                    <h6 style={{flex: 1, margin: 0}}>Orthoptie</h6>
                </div>
                <div className="valign-wrapper" style={{gap: 20}}>
                    {renderBadge(pricing.cv_enabled, "Télétransmettre à l'aide du logiciel Méd'OC")}
                    <h6 style={{flex: 1, margin: 0}}>Télétransmission</h6>
                </div>
            </div>

        </>
    }

    const renderActPrices = () => {
        return <>
            <h4 className="center">Tarifs par acte</h4>
            <label>Ce tableau recense vos gains pour chaque acte réalisé</label>
            <table className="bordered striped highlight">
                <thead>
                <tr>
                    <th>Acte</th>
                    <th>Gains</th>
                </tr>
                </thead>
                <tbody>
                {prices.sort((a, b) => a.act_name > b.act_name).map((price, index) => <tr key={index}>
                    <td>{price.act_name}</td>
                    <td>{price.income.toFixed(2)} €</td>
                </tr>)}
                </tbody>
            </table>
        </>

    }

    return <div style={{display: "flex", flexDirection: "column", padding: 20}}>
        {renderPricing()}
        {renderOptions()}
        {renderActPrices()}
    </div>
}

export default PricesOverview;