import React from 'react';
import PropTypes from "prop-types"
import MesureStore from "../../Appointments/Mesures/MesureStore";
import Cookies from "js-cookie";
import CancelButton from "./CancelButton";
import {t} from "../../I18n/i18n"

function AppointmentValidationButton({disabled, onValidation, onCheck, onBack, backButton}) {
    const text = `Une fois que vous avez terminé l'examen, vous pouvez le valider en cliquant sur le bouton "Terminer".
Vous pouvez également vérifier les informations avant de valider en cliquant sur le bouton "Vérifier".\n
Les informations sont tout de même enregistrées au fur et à mesure de votre examen, mais l'examen ne sera considéré comme terminé qu'au moment où vous aurez finalisé l'examen avec un de ces deux boutons.
`
    const renderFirstButton = () => {
        if (backButton) return <a className={`btn-small black darken-2 z-depth-0 truncate ${disabled ? "disabled" : ""}`}
                                  title={"Retourner à l'examen"}
                                  onClick={onBack}
                                  style={{borderRadius: "4px 0 0 4px", marginInline: 0}}>
            <i className="material-icons left">chevron_left</i>
            {t("BYPASS_CONSTRAINT.BACT_TO_CONSULTATION")}
        </a>

        return <a className={`btn-small blue darken-2 z-depth-0 truncate ${disabled ? "disabled" : ""}`}
                  title={t("BYPASS_CONSTRAINT.CHECK_BEFORE")}
                  onClick={onCheck}
                  style={{borderRadius: "4px 0 0 4px", marginInline: 0}}>
            <i className="material-icons left">plagiarism</i>
            {t("BYPASS_CONSTRAINT.CHECK")}
        </a>
    }

    return <div style={{display: "flex"}}>
        {renderFirstButton()}
        <a className={`btn-small green darken-2 z-depth-0 truncate ${disabled ? "disabled" : ""}`}
           onClick={onValidation}
           title={t("BYPASS_CONSTRAINT.MARK_AS_COMPLETE")}
           style={{borderRadius: 0, marginInline: 0}}>
            <i className="material-icons left">check</i>
            {t("BYPASS_CONSTRAINT.FINISH")}
        </a>
        <CancelButton name="patient_overview_cancel_button" id={MesureStore.appointement_id}
                      title={t("BYPASS_CONSTRAINT.CANCEL_BUTTON_TITLE")}
                      onCancel={
                          () => {
                              Cookies.remove('last_appointements_data_done')
                              Cookies.remove('last_appointement_id')
                              Cookies.remove('last_appointement_done')
                              Cookies.remove('appointement_id')
                              window.location.href = "/agenda/index"
                          }}
                      style={{borderRadius: "0 4px 4px 0", marginInline: 0}}
        />
        {/*<HelpButton size={"tiny"} text={text}/>*/}
    </div>
}

AppointmentValidationButton.propTypes = {
    disabled: PropTypes.bool,
    onValidation: PropTypes.func.isRequired,
    onCheck: PropTypes.func.isRequired,
};

AppointmentValidationButton.defaultProps = {
    disabled: false,
    onValidation: () => console.log("validation"),
    onCheck: () => console.log("check"),
};

export default AppointmentValidationButton;