import React from "react";
import axios from "axios";
import {toast} from "react-toastify";
import Validator from "../../Shared/Validator";
import MesureStore from "../../Appointments/Mesures/MesureStore";
import {Observer} from "mobx-react";
import {t} from "../../I18n/i18n"

function BypassConstraintsButton() {
    const bypassConstraints = (reason = undefined) => {
        axios.patch(`/api/appointment/bypass`, {
            appointment_id: MesureStore.appointement_id,
            reason: reason
        }).then((response) => {
            const text = response.data.bypassed ? t("BYPASS_CONSTRAINT.IGNORE") : t("BYPASS_CONSTRAINT.REAPPLIED")
            toast.success(`${t("BYPASS_CONSTRAINT.TEXT_1")}${text}${t("BYPASS_CONSTRAINT.TEXT_2")}`);
                MesureStore.constraints_bypassed = response.data.bypassed
        }).catch((error) => {
            if (error.response.status === 400) return toast.error(t("BYPASS_CONSTRAINT.TOAST_ERROR_400"))
            toast.error(t("BYPASS_CONSTRAINT.TOAST_ERROR_UPDATE"))
        })
    }

    const render = () => {
        if (MesureStore.constraints_bypassed) return <a className="btn-small blue darken-2 z-depth-0 truncate"
                                                        title="Rétablir les régles"
                                                        onClick={() => bypassConstraints()}>
            <i className="material-icons left">lock</i>
            {t("BYPASS_CONSTRAINT.REAPPLYING_CONSTRAINT")}
        </a>

        return  <Validator
            id={`bypass_constraints_${MesureStore.appointement_id}`}
            onValidation={(v) => bypassConstraints(v)}
            text={t("BYPASS_CONSTRAINT.SURE")}
            detail={t("BYPASS_CONSTRAINT.HUGE_TEXT")}
            validationColor="black" validationText={t("BYPASS_CONSTRAINT.IGNORE_CONSTRAINT")}
            validationIcon="lock_open" abortColor="blue darken-2" abortIcon="close"
            abortText={t("CANCEL")}
            input
        >
            <a className={`btn-small black z-depth-0`} title={t("BYPASS_CONSTRAINT.IGNORE_RULES")}>
                <i className="material-icons left">lock_open</i>
                {t("BYPASS_CONSTRAINT.IGNORE_CONSTRAINT")}
            </a>
        </Validator>
    }



    return <Observer>
        {() => render()}
    </Observer>
}

export default BypassConstraintsButton;