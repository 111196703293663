import React, {useState} from 'react'
import {toast} from "react-toastify";
import BorderedDisplay from "../Shared/UiKit/BorderedDisplay";
import MedicalEventStore from "./MedicalEventStore";
import Regex from "../Shared/Validation/Regex";

const _ = require('lodash')
const AddPatient = () => {
    const defaultPatient = {
        firstname: "",
        lastname: "",
        email: "",
        telephone: "",
        birthday: "",
        ssn: "",
        organism_code: undefined,
        health_insurance: undefined
    }
    const [patient, setPatient] = useState(defaultPatient)

    const validatePatient = () => {
        if(_.isEmpty(patient.firstname)) {
            toast.error("Le prénom est obligatoire")
            return false
        }
        if(_.isEmpty(patient.lastname)) {
            toast.error("Le nom est obligatoire")
            return false
        }
        if(!_.isEmpty(patient.email) && !Regex.validateEmail(patient.email)) {
            toast.error("L'email est invalide")
            return false
        }
        if(!_.isEmpty(patient.telephone) && !Regex.validatePhoneNumber(patient.telephone)) {
            toast.error("Le téléphone est invalide")
            return false
        }
        if(_.isEmpty(patient.birthday)) {
            toast.error("La date de naissance est obligatoire")
            return false
        }
        if(_.isEmpty(patient.ssn)) {
            toast.error("Le numéro de sécurité sociale est obligatoire")
            return false
        }
        if(!Regex.validateSsn(patient.ssn)) {
            toast.error("Le numéro de sécurité sociale est invalide")
            return false
        }
        if(!Helper.checkSsnKey(patient.ssn)) {
            toast.error("La clé du numéro de sécurité sociale est invalide")
            return false
        }
        if(!_.isEmpty(patient.organism_code) && !Regex.validateOrganismCode(patient.organism_code)) {
            toast.error("Le code organisme est invalide")
            return false
        }
        return true
    }

    const addPatient = () => {
        if(!validatePatient()) return

        MedicalEventStore.addPatient(patient).then(_ => {
            setPatient(defaultPatient)
        }).catch(_ => toast.error("Erreur lors de l'ajout du patient"))

    }

    return <BorderedDisplay text={"Ajouter un patient"}>
        <div className="valign-wrapper" style={{gap: 20, alignItems: "flex-start"}}>
            <div className="valign-wrapper" style={{gap: 20, flexWrap: "wrap", flex: 1}}>
                <div className="input-field outlined">
                    <input id="firstname" type="text" className="validate" value={patient.firstname}
                           onChange={e => setPatient({...patient, firstname: e.target.value})}/>
                    <label htmlFor="firstname">Prénom</label>
                </div>
                <div className="input-field outlined">
                    <input id="lastname" type="text" className="validate" value={patient.lastname}
                           onChange={e => setPatient({...patient, lastname: e.target.value})}/>
                    <label htmlFor="lastname">Nom</label>
                </div>
                <div className="input-field outlined">
                    <input id="email" type="email" className="validate" value={patient.email}
                           onChange={e => setPatient({...patient, email: e.target.value})}/>
                    <label htmlFor="lastname">Email</label>
                </div>
                <div className="input-field outlined">
                    <input id="telephone" type="text" className="validate" value={patient.telephone}
                           onChange={e => setPatient({...patient, telephone: e.target.value})}/>
                    <label htmlFor="lastname">Téléphone</label>
                </div>
                <div className="input-field outlined">
                    <input id="birthdate" type="date" className="validate" value={patient.birthday}
                           onChange={e => setPatient({...patient, birthday: e.target.value})}/>
                    <label htmlFor="birthdate">Date de naissance</label>
                </div>
                <div className="input-field outlined">
                    <input id="phone" type="text" className="validate" value={patient.ssn}
                           onChange={e => setPatient({...patient, ssn: e.target.value})}/>
                    <label htmlFor="phone">N° sécurité sociale</label>
                </div>
                <div className="input-field outlined">
                    <input id="phone" type="text" className="validate" value={patient.organism_code}
                           onChange={e => setPatient({...patient, organism_code: e.target.value})}/>
                    <label htmlFor="phone">Code organisme</label>
                </div>
                <div className="input-field outlined">
                    <input id="phone" type="text" className="validate" value={patient.health_insurance}
                           onChange={e => setPatient({...patient, health_insurance: e.target.value})}/>
                    <label htmlFor="phone">Mutuelle</label>
                </div>
            </div>
            <a className="btn blue darken-2 z-depth-0" onClick={addPatient}>
                <i className="material-icons left">add</i>
                Ajouter
            </a>

        </div>
    </BorderedDisplay>
}

export default AddPatient