import React, {useEffect, useState} from 'react';
import jsPDF from "jspdf";
import html2pdf from "html-to-pdf-js";

function DocumentTile({doc, onPdfSelect, mobile, metadata}) {
    const [loading, setLoading] = useState(false);
    const [blob, setBlob] = useState(null);

    useEffect(() => {
        htmlToPdf(doc.url + "&print=false")
    }, [doc])

    const htmlToPdf = (url) => {
        setLoading(true)
        const iframe = document.createElement('iframe');
        iframe.src = url;
        iframe.style.visibility = 'hidden';  // Hide the iframe, but keep it in the layout
        iframe.style.position = 'absolute';
        iframe.style.left = '-9999px';
        iframe.style.top = '-9999px';
        //iframe must be A4
        iframe.style.width = '210mm';
        iframe.style.height = '297mm';
        //add data is loaded to 0
        document.body.appendChild(iframe);

        let loaded = false;
        iframe.onload = () => loaded = true;

        // start busy wait until iframe.contentWindow.status == "ready" and iframe loaded
        const interval = setInterval(() => {
            if (iframe.contentWindow.status === "ready" && loaded) {
                clearInterval(interval);
                iframeToBlob(iframe);
            }
        }, 200)
    }

    // Only JSPDF, pagebreaks don't work but text is selectable
    // will need to figure that out because it's overall way better
    const iframeToBlobTextSelectable = (iframe) => {
        var pdf = new jsPDF('p', 'pt', 'a4');
        pdf.setFontSize(12);
        const content = iframe.contentWindow.document.getElementById("pdf-document")
        pdf.html(content, {
            callback: function (pdf) {
                setBlob(pdf.output('blob'));
                setLoading(false);
            }, margin: [10, 20],
            html2canvas: {
                scale: 1
            },
            pagebreak: {mode: 'avoid-all'}
        });
    }

    const iframeToBlob = (iframe) => {
        let worker = html2pdf()
        const html = iframe.contentWindow.document.querySelector(".pdf-document")

        if(!html) {
            document.body.removeChild(iframe)
            setBlob({error: true});
            return
        }

        const contentHeight = html.offsetHeight;
        const pdfPageHeight = 1040;
        const nbPages = Math.ceil(contentHeight / pdfPageHeight);
        html.style.height = nbPages * pdfPageHeight;

        // const hasLowMemory = navigator.deviceMemory && navigator.deviceMemory < 2; // e.g., less than 2GB RAM
        const isMobile = /Mobi|Android/i.test(navigator.userAgent);
        const imgQuality = isMobile ? 0.5 : 1;
        const scale = isMobile ? 2 : 4;

        worker.from(html)
            .set({
                margin: [10, 0],
                pagebreak: {mode: ['avoid-all', 'css', 'legacy']},
                filename: `${doc.title}_${Helper.formatDate(metadata?.appointment?.start)}`,
                html2canvas: {scale: scale},
                jsPDF: {unit: 'mm', format: 'a4', orientation: 'p'},
                image: {type: 'jpeg', quality: imgQuality}
            }).to('pdf').output('blob').then((blob) => {
            setBlob(blob);
            setLoading(false);
            document.body.removeChild(iframe)
        }).catch((err) => {
            document.body.removeChild(iframe)
            console.log(err)
        })
    }

    const print = () => {
        if (!blob) return
        const blobURL = URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobURL;
        a.download = `${doc.title}_${Helper.formatDate(metadata?.appointment?.start)}`; // provide a filename

        document.body.appendChild(a);
        a.click();

        // cleanup
        window.URL.revokeObjectURL(blobURL);
    }

    const onClick = () => {
        if (blob) window.innerWidth < 992.99 ? print() : onPdfSelect(blob)
    }

    if(blob?.error) return <div className="valign-wrapper"
                                style={{gap: 10, padding: "5px 10px", width: "100%"}}
                                title="Une erreur est survenue lors de la génération du document.">
        <i className={`tiny material-icons grey-text text-darken-2`}>error</i>
        <h5 className={loading ? "grey-text text-darken-2" : ""} style={{margin: 0}}>{doc.title}</h5>
    </div>

    return (
        <div className="valign-wrapper" style={{gap: 10, padding: "5px 10px", width: "100%"}} onClick={onClick}>
            <i className={`rotating tiny material-icons ${loading ? "grey-text text-darken-2" : "hide"}`}>sync</i>
            <h5 className={loading ? "grey-text text-darken-2" : ""} style={{margin: 0}}>{doc.title}</h5>
        </div>
    )

}

DocumentTile.defaultProps = {
    mobile: false
}

export default DocumentTile;