import React from "react";
import PatientsToSee from "./PatientsToSee";
import CurrentlyAnalysed from "./CurrentlyAnalysed";
import IncompleteExaminations from "./IncompleteExaminations";
import ToRedirect from "./ToRedirect";
import MonthRevenues from "./MonthRevenues";
import ExamsThisMonth from "./ExamsThisMonth";
import MonthActivity from "./MonthActivity";
import AnnualActivity from "./AnnualActivity";
import MonthlyRevenues from "./MonthlyRevenues";
import PatientsNotation from "./PatientsNotation";

function Dashboard({}) {

    return (
        <div className="container" style={{padding: 25, width: "100%", display: "flex", flexDirection: "column", gap: 20}}>
            <div className="row" style={{width: "100%", gap: 20}}>
                <div className="col s12 l3"><PatientsToSee/></div>
                <div className="col s12 l3"><CurrentlyAnalysed/></div>
                <div className="col s12 l3"><IncompleteExaminations/></div>
                <div className="col s12 l3"><ToRedirect/></div>
            </div>
            <div className="row" style={{width: "100%", gap: 20}}>
                <div className="col s12 l6">
                    <div className="row" style={{gap: 20, height: "100%"}}>
                        <div className="col s12 l6"><MonthRevenues/></div>
                        <div className="col s12 l6"><ExamsThisMonth/></div>
                        <div className="col s12 l6"><MonthActivity/></div>
                        <div className="col s12 l6"><AnnualActivity/></div>
                    </div>
                </div>
                <div className="col s12 l6">
                    <div className="row" style={{gap: 20, height: "100%"}}>
                        <div className="col s12"><MonthlyRevenues/></div>
                        <div className="col s12"><PatientsNotation/></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard