import React, {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import moment from "moment";

export default function PaiementErrorDetail() {
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('[name=csrf-token]').content;

    const [authorizeBtn, setAuthorizeBtn] = useState(false);
    //take id from url
    const [data, setData] = useState({
        paiement: {},
        error: {},
        orthoptiste: {},
        messages: [false, []]
    });
    const params = useParams();
    const pid = params.id;


    useEffect(() => {
        loadData();
    }, []);

    const markAsPaid = () => {
        axios.put("/admin/console/bills/paiement_err_mark_as_paid", {
            paiement_err_id: pid
        }).then((data) => {
            toast.success("Paiement marqué comme payé")
            loadData();
        }).catch((err) => {
            toast.error("Une erreur est survenue")
        })
    }
    const reexecute = () => {
        axios.post("/admin/console/bills/paiement_err_reexecute", {
            paiement_err_id: pid
        }).then((data) => {
            toast.success("demande réexécution envoyée")
            loadData();
        }).catch((err) => {
            toast.error("Une erreur est survenue")
        })
    }
    const loadData = () => {
        axios.get("/admin/console/bills/paiement_errors_details/" + pid).then((data) => {
            setData(data.data.data)
        })
    }
    const deletePaiementErr = () => {
        axios.delete("/admin/console/bills/delete_paiement_error/" + pid).then((data) => {
            toast.success("L'erreur est maintenant ignorée")
            window.location.href = "/admin/console#/paiement_errors"; //ouais flemme je sais c est moche
        }).catch((err) => {
            toast.error("Une erreur est survenue")
        })
    }

    return (
        <div>
            <div className="row">
                <div className="col s4">
                    <h4 className="center">Informations concernant l'erreur</h4>
                    <ul>
                        <li><b>Message:</b> {data.error.message}</li>
                        <li><b>Date:</b> {moment(data.error.created_at).format("DD-MM-YYYY hh:mm")}</li>
                        <li><b>Paiement:</b> {data.error.paiement_id}</li>
                    </ul>
                    <h4 className="center">Informations concernant le paiement</h4>
                    <ul>
                        <li><b>totalOrthoptiste:</b> {data.paiement.totalOrthoptiste / 100.0} € sera débit</li>
                        <li><b>totalPlateforme</b> {data.paiement.totalPlateforme / 100.0} € (pour temeoo)</li>
                        <li><b>total_ophtalmologiste</b> {data.paiement.total_ophtalmologiste / 100.0}€ (pour
                            l'ophtalmo)
                        </li>
                        <li><b>Date de début</b> {moment(data.paiement.from).format("DD-MM-YYYY hh:mm")}</li>
                        <li><b>Date de début</b> {moment(data.paiement.to).format("DD-MM-YYYY hh:mm")}</li>
                        <li><b>Date de création</b> {moment(data.paiement.created_at).format("DD-MM-YYYY hh:mm")}</li>
                        <li><b className="blue-text">STATUS {data.paiement.status}</b></li>
                    </ul>
                </div>
                <div className="col s4">
                    <h4 className="center">Messages</h4>
                    {data.messages[0] ? (
                        <h4><i className="material-icons green-text left">done</i> Aucune erreur obvious, le paiement
                            peut-être réutilisé</h4>
                    ) : (
                        <div>
                            <p>le paiement est impossible à lancer tel quel:</p>
                            <ul>
                                {data.messages[1].map((i, index) => (
                                    <li key={index} className="red-text"><i
                                        className="material-icons red-text left">warning</i> {i} </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <div className="divider"></div>
                    <h4 className="center">Orthoptiste</h4>
                    <ul>
                        <li><b>nom prénom</b> {data.orthoptiste.prenom} {data.orthoptiste.nom} </li>
                        <li><b> mail</b> {data.orthoptiste.email}</li>
                        <li><b>id</b> {data.orthoptiste.id}</li>
                    </ul>
                </div>
                <div className="col s4">
                    <h4 className="center">Actions</h4>
                    {/* checkbox to unlock buttons */}
                    <p>
                        <label>
                            <input type="checkbox" value={authorizeBtn}
                                   onChange={(e) => setAuthorizeBtn(!authorizeBtn)}/>
                            <span><b>[DANGER]</b> Débloquer les actions</span>
                        </label>
                    </p>
                    <button className="btn waves-effect waves-light red"
                            type="submit" name="action"
                            onClick={markAsPaid}
                            disabled={!authorizeBtn}>Marquer comme payé
                    </button>
                    <>
                        {data.messages[0] ? (
                            <span></span>
                        ) : (
                            <p>Impossible de relancer, données manquantes (cf messages)</p>
                        )}
                        <button
                            className="btn waves-effect waves-light"
                            type="submit" name="action"
                            disabled={!authorizeBtn || !data.messages[0]}
                            onClick={reexecute}
                        >
                            Réexecuter le paiement
                        </button>
                    </>
                    <button
                        className="btn waves-effect waves-light black white-text"
                        type="submit" name="action"
                        disabled={!authorizeBtn}
                        onClick={deletePaiementErr}
                    >
                        Ignorer cette alerte
                    </button>
                </div>

            </div>
        </div>
    )

}